import { useEffect, useRef, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import { IoMdEye } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import { createEditor } from 'slate'
// import { Slate, Editable, withReact } from 'slate-react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RiUploadCloud2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { useGetBlogByIdQuery, useUpdateBlogMutation } from "../../store/services/blogServices";
import { FiTrash2 } from "react-icons/fi";
import { categories, popularTopics } from "./AddBlog";
import { useDispatch } from "react-redux";
import { openModalWithTimeout } from "../../store/alertSlice";
import { TfiTrash } from "react-icons/tfi";
import { GoDownload } from "react-icons/go";

const removeHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "")
}

const extractFileNameFromPath = (path) => {
    const normalizedPath = path.replace(/\\/g, '/');
    const fileName = normalizedPath.split('/').pop();
    return fileName
}

export default function EditBlog() {

    const { id } = useParams()
    const navigate = useNavigate()

    const { data } = useGetBlogByIdQuery(id)
    const [updateBlog] = useUpdateBlogMutation()
    const [editedBlog, setEditedBlog] = useState({ title: '', category: '', text: '', image: '' })
    const [htmlText, setHtmlText] = useState('')
    const [image, setImage] = useState(null)
    const [imageUploadedTime, setImageUploadedTime] = useState('')
    const [imagePreview, setImagePreview] = useState(null)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()

    const fileInputRef = useRef(null);

    useEffect(() => {
        if (data) {
            setEditedBlog(data)
            setHtmlText(data.text)
        }
    }, [data])

    const handleEditBlogSubmit = async (e) => {
        e.preventDefault();
        const blogData = {
            ...editedBlog,
            text: htmlText,
            ...(image && { image })
        };
        if (validateForm()) {
            try {
                const formData = new FormData();
                Object.keys(blogData).forEach((key) => formData.append(key, blogData[key]));
                const result = await updateBlog({ data: formData, id: id }).unwrap();
                console.log('Item updated:', result);
                navigate('/blogs')
                dispatch(openModalWithTimeout({text: 'Blog successfully updated', type: 'success'}, 3000))
            } catch (error) {
                console.error('Failed to update item:', error);
            }
        }
        else {
            console.log("errors")
        }
    }

    const handleBlogEdit = (e) => {
        setEditedBlog({ ...editedBlog, [e.target.id]: e.target.value })
    }

    const handlePictureChange = (e) => {
        setImage(e.target.files[0])
        if (e.target.files[0]) {
            let currentErrors = errors;
            delete currentErrors.image
            setErrors(currentErrors)
        }
    }

    useEffect(() => {
        if (image) {
            setImagePreview(URL.createObjectURL(image))
            setImageUploadedTime(new Date(Date.now()).toLocaleString('en-GB'))
        }
    }, [image])

    const validateForm = () => {
        let formErrors = {};
        if (!editedBlog.title) formErrors.title = "Title is required";
        if (removeHtmlTags(htmlText).length < 1) formErrors.text = "Text is required";
        if (!image && !editedBlog.image_path) formErrors.image = "Image is required"

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const handleClearFileInput = () => {
        setEditedBlog({ ...editedBlog, image_path: '' })
        fileInputRef.current.value = '';
        setImage(null)
        setImagePreview(null)
    }

    const details = {
        title: 'Edit Blog',
    }

    const buttons = [
        {
            label: 'Shiko si duket',
            icon: <IoMdEye />,
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            shadow: 'shadow-sm',
            linkTo: null,
            onClick: (e) => {
                e.preventDefault();
                window.open(`https://pronascout.com/blog/${id}`, '_blank')
            },
        },
        {
            label: 'Cancel',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            shadow: 'shadow-sm',
            linkTo: '/blogs',
            onClick: null,
        },
        {
            label: 'Save',
            icon: <MdDone />,
            color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]',
            border: 'border border-b-2 border-primary',
            shadow: 'shadow-md',
            linkTo: null,
            onClick: handleEditBlogSubmit,
        }
    ]

    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    ];

    const module = {
        toolbar: toolbarOptions
    }

    const handleChange = (content, delta, source, editor) => {
        setHtmlText(content)
    };

    const editorStyle = {
        height: '500px',
    }

    return (
        <div>
            <DashboardHeader details={details} buttons={buttons} />
            <div className="bg-[#F9FAFB] rounded-b-xl">
                <hr />
                <div className="p-6 rounded-b-xl text-sm">
                    <form>
                        <div className="flex gap-8">
                            <div className="w-full">
                                <div>
                                    <label htmlFor="title">Title</label>
                                    <input type="text" id="title" value={editedBlog.title} onChange={handleBlogEdit} name="title" className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium" />
                                </div>
                                {errors.title && <div className="text-bloodred font-bold">{errors.title}</div>}
                            </div>
                            <div className="w-full flex gap-4">
                                <div className="w-full">
                                    <label htmlFor="title">Select Category</label>
                                    <div className="relative w-full">
                                        <select name="category" id="category" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" onChange={handleBlogEdit} value={editedBlog.category} disabled>
                                            {categories.map(category => (
                                                <option value={category}>{category}</option>
                                            ))}
                                        </select>
                                        <div className="absolute top-1/2 right-0 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label htmlFor="popular_topics">Select Popular Topic</label>
                                    <div className="relative w-full">
                                        <select name="popular_topics" id="popular_topics" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={editedBlog.popular_topics} onChange={handleBlogEdit} disabled>
                                            {popularTopics.map(topic => (
                                                <option value={topic}>{topic}</option>
                                            ))}
                                        </select>
                                        <div className="absolute top-1/2 right-0 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <Slate editor={editor} initialValue={initialValue}>
                <Editable/>
            </Slate> */}
                {errors.text && <div className="text-bloodred px-6 pb-2 text-sm font-bold">{errors.text}</div>}
                <ReactQuill theme="snow" modules={module} value={htmlText} onChange={handleChange} style={editorStyle} className="p-6 pt-0 no-scrollbar" />
                <div className="p-6">
                    {editedBlog.image_path && (
                        <div className="pb-6">
                            <h1 className="my-4 font-semibold text-gray-600">Fotot e ngarkuara</h1>
                            <div className="flex items-center gap-8">
                                <div className="flex flex-col gap-1">
                                    <div className="font-bold">{extractFileNameFromPath(editedBlog.image_path)}</div>
                                    <div className="text-gray-400 text-sm">{new Date(editedBlog.created_at).toLocaleString('en-GB')}</div>
                                </div>
                                <div className="w-32 h-16 rounded-xl">
                                    <img src={`${process.env.REACT_APP_BACKEND_HOST}/${editedBlog.image_path}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                </div>
                                <div className="flex gap-4">
                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={handleClearFileInput} />
                                    <GoDownload fontSize={20} title="Download" />
                                </div>
                            </div>
                        </div>
                    )}
                    {image && (
                        <div className="pb-6">
                            <h1 className="my-4 font-semibold text-gray-600">Fotot e ngarkuara</h1>
                            <div className="flex items-center gap-8">
                                <div className="flex flex-col gap-1">
                                    <div className="font-bold">{image.name}</div>
                                    <div className="text-gray-400 text-sm">{imageUploadedTime}</div>
                                </div>
                                <div className="w-32 h-16 rounded-xl">
                                    <img src={`${imagePreview}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                </div>
                                <div className="flex gap-4">
                                    <TfiTrash fontSize={20} color="red" cursor="pointer" title="Remove" onClick={handleClearFileInput} />
                                    <GoDownload fontSize={20} title="Download" />
                                </div>
                            </div>
                        </div>
                    )}
                    <input
                        id="files"
                        name="files"
                        type="file"
                        ref={fileInputRef}
                        accept="image/png, image/jpg, image/jpeg"
                        className="hidden"
                        onChange={handlePictureChange}
                        onDrop={handlePictureChange}
                    />
                    {editedBlog.image_path || image ? (
                        <div className="h-[280px] flex items-center w-full p-2 bg-[#FBFEFC] rounded-xl border-2 border-dashed border-green-300 flex justify-center">
                            <div className="my-4">
                                <div className="rounded-full w-14 h-14 bg-[#E7F6EC] flex justify-center items-center mx-auto cursor-pointer">
                                    <div className="rounded-full w-6 h-6 flex justify-center items-center bg-green-600">
                                        <MdDone fontSize={16} color="white" />
                                    </div>
                                </div>
                                <div className="text-black my-2 text-center">
                                    <span className="font-semibold">Fotoja eshte ngarkuar</span>
                                </div>
                                <div className="p-1 px-4 w-max rounded-lg text-gray-400 mx-auto cursor-pointer flex gap-2 items-center" onClick={handleClearFileInput}>
                                    <span><FiTrash2 fontSize={16} /></span>
                                    <span className="leading-3 font-semibold">Pastro ngarkimin</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <label htmlFor="files">
                            <div className="pt-6">
                                {errors.image && <div className="text-bloodred text-sm pb-2 font-bold">{errors.image}</div>}
                                <div className="h-[280px] flex items-center w-full p-2 bg-white rounded-xl border-2 border-dashed border-gray-200 flex justify-center cursor-pointer">
                                    <div className="my-4">
                                        <div className="rounded-full w-14 h-14 bg-gray-100 flex justify-center items-center mx-auto  cursor-pointer">
                                            <RiUploadCloud2Line fontSize={28} color="#555" />
                                        </div>
                                        <div className="text-appgray my-4 text-center">
                                            <span className="text-bloodred font-semibold cursor-pointer">Kliko per te ngarkuar</span> ose terheq dhe lesho
                                        </div>
                                        <div className="p-2 w-[350px] relative my-4 text-gray-400">
                                            <hr className="border border-[whitesmoke]" />
                                            <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-white px-3">
                                                OSE
                                            </div>
                                        </div>
                                        <div className="p-2 px-4 bg-bloodred w-max rounded-lg text-white mx-auto cursor-pointer">Shfleto Dosjet</div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    )}
                </div>
            </div>
        </div>
    )
}