import { createSlice } from "@reduxjs/toolkit";

const initialCharacteristicsModalState = { isOpen: false, title: '', characteristics: [] }

const characteristicsModalSlice = createSlice({
    name: 'characteristics_modal',
    initialState: initialCharacteristicsModalState,
    reducers: {
        openModal(state, action) {
            const characteristics = action.payload.characteristics
            const title = action.payload.title
            state.isOpen = true
            state.title = title;
            state.characteristics = characteristics
        },
        closeModal(state) {
            state.isOpen = false
            state.title = ''
            state.characteristics = []
        },
    }
})

export const characteristicsModalActions = characteristicsModalSlice.actions;
export default characteristicsModalSlice