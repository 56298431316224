import { createSlice } from "@reduxjs/toolkit";

const initialMenuState = { isBigMenu: false, activeCategory: '' }

const menuSlice = createSlice({
    name: 'menu',
    initialState: initialMenuState,
    reducers: {
        changeMenuSize(state) {
            state.isBigMenu = !state.isBigMenu
        },
        changeActiveCategory(state, action) {
            const category = action.payload.category
            if (state.activeCategory === category) {
                return;
            }
            state.activeCategory = action.payload.category
        }
    }
})

export const menuActions = menuSlice.actions;
export default menuSlice