import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    id: '',
    name: '',
    created_at: '',
    phone_number: '',
    email: '',
    role_type: '',
    user_type: '',
    password: '',
    verified: false,
    image: null,
    business_certificate: null
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        fillUser(state, action) {
            const { id, name, created_at, phone_number, email, image, role_type, user_type, verified, business_certificate } = action.payload.user
            state.id = id;
            state.name = name;
            state.created_at = created_at;
            state.phone_number = phone_number;
            state.email = email;
            state.image = image;
            state.role_type = role_type;
            state.user_type = user_type;
            state.verified = verified;
            state.business_certificate = business_certificate
        },
        resetUser(state) {
            state.id = '';
            state.name = '';
            state.created_at = '';
            state.phone_number = '';
            state.email = '';
            state.role_type = '';
            state.user_type = '';
            state.verified = false;
            state.image = null;
        }
    }
})

export const userActions = userSlice.actions;
export default userSlice