import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import DashboardTable from "../DashboardTable"
import BlogList from "./BlogList"
import { BiPlus } from "react-icons/bi"
import { useEffect, useState } from "react"
import { useGetBlogsQuery } from "../../store/services/blogServices"
import ReactModal from "../ReactModal"
import Pagination from "../Pagination";
import { useNavigate } from "react-router"

const blogs = [
    {
        id: 1,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car maintenance',
        text: 'Direct/parallel import – advantages and risks advantages and risks advantages and risks advantages and risks advantages and risks', // work on this
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 2,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'These are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars', // work on this
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 3,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'The purchase of officially imported vehicles officially imported vehicles officially imported vehicles officially imported vehicles', // work on this
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 4,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car maintenance',
        text: 'Direct/parallel import – advantages and risks advantages and risks advantages and risks advantages and risks advantages and risks', // work on this
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 5,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'These are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars', // work on this
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 6,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'The purchase of officially imported vehicles officially imported vehicles officially imported vehicles officially imported vehicles', // work on this
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 7,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car maintenance',
        text: 'Direct/parallel import – advantages and risks advantages and risks advantages and risks advantages and risks advantages and risks', // work on this
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 8,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'These are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars', // work on this
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 9,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'The purchase of officially imported vehicles officially imported vehicles officially imported vehicles officially imported vehicles', // work on this
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 10,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car maintenance',
        text: 'Direct/parallel import – advantages and risks advantages and risks advantages and risks advantages and risks advantages and risks', // work on this
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 11,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'These are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars', // work on this
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 12,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'The purchase of officially imported vehicles officially imported vehicles officially imported vehicles officially imported vehicles', // work on this
        img: 'unsplash_-oVaYMgBMbs.png',
    },
    {
        id: 13,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car maintenance',
        text: 'Direct/parallel import – advantages and risks advantages and risks advantages and risks advantages and risks advantages and risks', // work on this
        img: 'unsplash_3ZUsNJhi_Ik.png',
    },
    {
        id: 14,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'These are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars are the 10 best four-wheel drive cars', // work on this
        img: 'unsplash_gts_Eh4g1lk.png',
    },
    {
        id: 15,
        title: 'Audi e-tron S Sportback – The electric favorite of the Swiss',
        category: 'Car equipment',
        text: 'The purchase of officially imported vehicles officially imported vehicles officially imported vehicles officially imported vehicles', // work on this
        img: 'unsplash_-oVaYMgBMbs.png',
    },
]

export default function BlogDashboard() {

    const [showFilters, setShowFilters] = useState(true)
    const [filterSelected, setFilterSelected] = useState("")
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const navigate = useNavigate()
    
    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    const { data, isLoading, isError, isSuccess } = useGetBlogsQuery(params)

    useEffect(() => {
        if (data) {
            setPages({ currentPage: data.currentPage, totalPages: data.totalPages, totalCount: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        // if (query.has("q")) {
        //     setSearchTextInput(query.get("q"))
        // }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/blogs?${urlParams}`)
    }, [params, navigate])

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setPages({ ...pages, currentPage: page })
        setParams({ ...params, page: page })
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Couldn't fetch data</div>
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const buttons = [
        { label: 'Add blog', color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]', border: 'border border-b-2 border-primary', icon: <BiPlus />, shadow: 'shadow-md', onClick: null, linkTo: "/blogs/add" }
    ]

    const details = {
        title: 'Blogs',
        count: data?.data.length
    }

    const tableData = {
        headLabels: ['Title', 'Text', 'Image'],
        list: <BlogList blogs={data?.data} />,
        data: data?.data,
        actions: true
    }

    const filters = {
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: null,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    return (
        <>
            <ReactModal />
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <DashboardFilters filters={filters} />
            <div className="w-full">
                <DashboardTable tableData={tableData} />
            </div>
            <Pagination currentPage={currentPage} totalPages={pages?.totalPages} onPageChange={handlePageChange} />
        </>
    )
}