import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { accessoriesModalActions, characteristicsModalActions } from '../store/characteristicsModalSlice';
import { useLocation } from 'react-router';

const customStyles = {
    content: {
        width: '70%',
        height: '80%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px',
        outline: 'none',
        overflowY: 'scroll'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function CharacteristicsModal() {

    const location = useLocation()

    const dispatch = useDispatch()
    const modal = useSelector(state => state.characteristics_modal)

    function closeModal() {
        dispatch(characteristicsModalActions.closeModal())
    }

    useEffect(() => {
        closeModal();
    }, [location])

    return (
        <div>
            <Modal
                isOpen={modal.isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                className='overflow-y-scroll no-scrollbar'
                contentLabel="Example Modal"
            >
                <div className="bg-white p-6 modal-content no-scrollbar">
                    <ul className='[&>*:nth-child(even)]:bg-[#F9FAFB] [&>*:nth-child(even)]:border-y [&>*:nth-child(even)]:border-gray-200'>
                        <h1 className="text-[#667085] text-lg p-2 px-6 leading-8 sticky">{modal.title} </h1>
                        {modal.characteristics.map(characteristic => (
                            <li className='p-6 text-sm font-semibold'>{characteristic}</li>
                        ))}
                    </ul>
                </div>
            </Modal>
        </div>
    )
}