import { FiUsers } from "react-icons/fi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TbLayoutRows } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../store/menuSlice";
import { IoList } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { CiLogout } from "react-icons/ci";
import { authActions } from "../store/authSlice";

export default function Menu() {

    const isBigMenu = useSelector(state => state.menu.isBigMenu)
    const activeCategory = useSelector(state => state.menu.activeCategory)
    const token = localStorage.getItem('prona_userToken')
    const [isLogoutScreenActive, setIsLogoutScreenActive] = useState(false)
    const navigate = useNavigate()
    const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)

    const user = jwtDecode(token);

    const dispatch = useDispatch();

    const handleCategorySelect = (e) => {
        e.stopPropagation();
        const category = e.currentTarget.getAttribute("category")
        if (category === 'details') {
            if (!isBigMenu) {
                dispatch(menuActions.changeMenuSize())
            }
            setIsDetailsExpanded(true)
            return;
        }
        dispatch(menuActions.changeActiveCategory({ category: category }))
        setIsDetailsExpanded(false)
    }

    const handleMenuChange = () => {
        if (!isBigMenu) {
            setIsLogoutScreenActive(false)
        }
        dispatch(menuActions.changeMenuSize())
    }

    const handleProfileClick = (e) => {
        e.stopPropagation();
        setIsLogoutScreenActive(!isLogoutScreenActive)
    }

    const handleLogout = () => {
        dispatch(authActions.removeToken())
        localStorage.removeItem('prona_userToken')
        navigate('/login')
    }

    // const handleDetailsClick = (e) => {
    //     e.stopPropagation();
    //     setIsDetailsExpanded(!isDetailsExpanded)
    // }

    const handleDetailsSubCategoryClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const sub_category = e.target.getAttribute('name')
        navigate(`/${sub_category}`)
    }

    return (
        <div className={`${isBigMenu ? 'w-[250px]' : 'w-auto'} transition-all h-full flex-none border border-gray-200 rounded-xl flex flex-col justify-between cursor-pointer bg-white transition-all`} onClick={handleMenuChange}>
            <div>
                <div className="text-center p-2">
                    <img src="/pics/Frame 1618869319.svg" alt="Description of SVG" width={65} className="py-4" />
                </div>
                <div className="p-4 flex flex-col gap-1">
                    <div category="users" onClick={handleCategorySelect} title="Users">
                        <Link to="/users" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-[12px] border border-white rounded-xl ${activeCategory === 'users' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <FiUsers style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Users</div>}
                        </Link>
                    </div>
                    <div category="listings" onClick={handleCategorySelect} title="Listings">
                        <Link to="/listings" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'listings' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <TbLayoutRows style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Listings</div>}
                        </Link>
                    </div>
                    <div category="payments" onClick={handleCategorySelect} title="Payments">
                        <Link to="/payments" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'payments' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <LiaFileInvoiceDollarSolid style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Payments</div>}
                        </Link>
                    </div>
                    <div category="blogs" onClick={handleCategorySelect} title="Blogs">
                        <Link to="/blogs" className="w-full flex gap-4 items-center cursor-pointer">
                            <div className={`p-3 border border-white rounded-xl ${activeCategory === 'blogs' ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <IoNewspaperOutline style={{ fontSize: '24px' }} />
                            </div>
                            {isBigMenu && <div className="text-sm font-semibold">Blog</div>}
                        </Link>
                    </div>
                    <div category="details" onClick={handleCategorySelect} title="Details">
                        <Link to="/property_types" className={`w-full flex ${isBigMenu && 'gap-4'} items-start cursor-pointer`}>
                            <div className={`p-3 border border-white rounded-xl ${['property_types', 'sub_property_types', 'heating_types', 'furnishing_types', 'characteristics', 'locations'].includes(activeCategory) ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                                <IoList style={{ fontSize: '24px' }} />
                            </div>
                            <div className="flex flex-col gap-2">
                                {isBigMenu && <div className="w-full text-sm font-semibold py-4"><span className="hover:underline">Detajet</span></div>}
                                {isBigMenu && isDetailsExpanded && (
                                    <div className="text-sm flex flex-col gap-2 text-gray-400">
                                        <div name="property_types" onClick={handleDetailsSubCategoryClick} className={`hover:underline ${activeCategory === 'property_types' && 'font-bold text-black'}`}>Tipi i prones</div>
                                        <div name="sub_property_types" onClick={handleDetailsSubCategoryClick} className={`hover:underline ${activeCategory === 'sub_property_types' && 'font-bold text-black'}`}>Nentipi i prones</div>
                                        <div name="heating_types" onClick={handleDetailsSubCategoryClick} className={`hover:underline ${activeCategory === 'heating_types' && 'font-bold text-black'}`}>Sistemi i ngrohjes</div>
                                        <div name="furnishing_types" onClick={handleDetailsSubCategoryClick} className={`hover:underline ${activeCategory === 'furnishing_types' && 'font-bold text-black'}`}>Mobilimi</div>
                                        <div name="characteristics" onClick={handleDetailsSubCategoryClick} className={`hover:underline ${activeCategory === 'characteristics' && 'font-bold text-black'}`}>Karakteristikat</div>
                                        <div name="locations" onClick={handleDetailsSubCategoryClick} className={`hover:underline ${activeCategory === 'locations' && 'font-bold text-black'}`}>Lokacionet</div>
                                    </div>
                                )}
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="w-full p-4">
                <div className="w-full items-center flex gap-4" onClick={handleProfileClick}>
                    {/* <img src="/pics/user_profile.png" alt="img" className="w-[48px] h-[48px] rounded-full object-cover" /> */}
                    <div className="rounded-full border border-gray-200 p-2">
                        <CiUser fontSize={30} color="gray" />
                    </div>
                    {isBigMenu && <div className="font-semibold transition-all text-sm hover:underline">
                        {user.first_name && <span>{user.first_name} {user.last_name} ↓</span>}
                        {user.company_name && <span>{user.company_name} ↓</span>}
                    </div>}
                </div>
                <div className={`flex gap-2 border border-gray-200 items-center justify-center hover:border-red-200 hover:bg-red-50 text-red-600 rounded-lg ${isBigMenu && isLogoutScreenActive ? "transition-all max-h-48 max-w-82 p-2 mt-4" : 'max-h-0 max-w-0 overflow-hidden'}`} onClick={handleLogout}>Logout <CiLogout /></div>
            </div>
        </div>
    )
}