import axios from "axios";

const axiosInstance = axios.create({
 baseURL: `${process.env.REACT_APP_BACKEND_HOST}/api`,

 timeout: 10000,
 headers: {
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
 },
});

axiosInstance.interceptors.request.use(
 (config) => {
  const token = localStorage.getItem("prona_userToken");
  if (token) {
   config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
 },
 (error) => {
  return Promise.reject(error);
 }
);

export default axiosInstance;