import ArchivedPaymentsItem from "./ArchivedPaymentsItem"

export default function ArchivedPaymentsList({ payments }) {
    return (
        <>
            {payments.slice(0, 10).map(payment => (
                <ArchivedPaymentsItem payment={payment} />
            ))}
        </>
    )
}