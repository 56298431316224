import { useEffect, useRef, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import { MdDone } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RiUploadCloud2Line } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";
import { useCreateBlogMutation } from "../../store/services/blogServices";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { openModalWithTimeout } from "../../store/alertSlice";

const removeHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "")
}

export const categories = []
export const popularTopics = [];

export default function AddBlog() {

    const [createItem] = useCreateBlogMutation();
    const dispatch = useDispatch()

    const [blog, setBlog] = useState({
        title: '',
        // category: 'Car equipment',
        // popular_topics: 'CAR PURCHASE',
        text: '',
    })

    const [htmlText, setHtmlText] = useState('');

    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState('')
    const [imageUploadedTime, setImageUploadedTime] = useState('')

    const [errors, setErrors] = useState({})

    const fileInputRef = useRef(null);

    const navigate = useNavigate()

    const handleBlogChange = (e) => {
        setBlog({ ...blog, [e.target.name]: e.target.value })
        if (e.target.value) {
            let currentErrors = errors;
            delete currentErrors[e.target.name]
            setErrors(currentErrors)
        }
    }

    const validateForm = () => {
        let formErrors = {};
        if (!blog.title) formErrors.title = "Title is required";
        if (removeHtmlTags(blog.text).length < 1) formErrors.text = "Text is required";
        if (!image) formErrors.image = "Image is required"

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const details = {
        title: 'Add Blog',
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const blogData = {
            ...blog,
            image,
        };
        if (validateForm()) {
            try {
                const formData = new FormData();
                Object.keys(blogData).forEach((key) => formData.append(key, blogData[key]));
                const result = await createItem(formData).unwrap();
                console.log('Item created:', result);
                navigate('/blogs')
                dispatch(openModalWithTimeout({ text: 'Blog successfully created', type: 'success' }, 3000))
            } catch (error) {
                console.error('Failed to create item:', error);
            }
        }
        else {
            console.log("errors")
        }
    }

    const buttons = [
        {
            label: 'Cancel',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            shadow: 'shadow-sm',
            linkTo: "/blogs",
            onClick: null,
        },
        {
            label: 'Save',
            icon: <MdDone />,
            color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]',
            border: 'border border-b-2 border-primary',
            shadow: 'shadow-md',
            linkTo: null,
            onClick: handleFormSubmit,
        }
    ]

    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    ];


    const module = {
        toolbar: toolbarOptions
    }

    useEffect(() => {
        if (image) {
            setImagePreview(URL.createObjectURL(image))
            setImageUploadedTime(new Date(Date.now()).toLocaleString())
        }
    }, [image])

    useEffect(() => {
        setBlog({ ...blog, text: htmlText })
    }, [htmlText])

    const handleChange = (content, delta, source, editor) => {
        setHtmlText(content);
        if (content) {
            let currentErrors = errors;
            delete currentErrors.text
            setErrors(currentErrors)
        }
    };

    const editorStyle = {
        height: '500px',
    }

    const handlePictureChange = (e) => {
        setImage(e.target.files[0])
        if (e.target.files[0]) {
            let currentErrors = errors;
            delete currentErrors.image
            setErrors(currentErrors)
        }
    }

    const handleClearFileInput = () => {
        fileInputRef.current.value = '';
        setImage(null)
        setImagePreview(null)
    }

    return (
        <div>
            <DashboardHeader details={details} buttons={buttons} />
            <div className="bg-[#F9FAFB] rounded-b-xl">
                <hr />
                <div className="p-6 rounded-b-xl text-sm">
                    <form>
                        <div className="flex gap-8">
                            <div className="w-full">
                                <div>
                                    <label htmlFor="title">Title</label>
                                    <input type="text" id="title" name="title" value={blog.value} onChange={handleBlogChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium" />
                                </div>
                                {errors.title && <div className="text-bloodred font-bold">{errors.title}</div>}
                            </div>
                            <div className="w-full flex gap-4">
                                <div className="w-full">
                                    <label htmlFor="categoty">Select Category</label>
                                    <div className="relative w-full">
                                        <select name="category" id="category" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" onChange={handleBlogChange} disabled>
                                            {categories.map(category => (
                                                <option value={category}>{category}</option>
                                            ))}
                                        </select>
                                        <div className="absolute top-1/2 right-0 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label htmlFor="popular_topics">Select Popular Topic</label>
                                    <div className="relative w-full">
                                        <select name="popular_topics" id="popular_topics" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" onChange={handleBlogChange} disabled>
                                            {popularTopics.map(topic => (
                                                <option value={topic}>{topic}</option>
                                            ))}
                                        </select>
                                        <div className="absolute top-1/2 right-0 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <Slate editor={editor} initialValue={initialValue}>
                <Editable/>
            </Slate> */}
                {errors.text && <div className="text-bloodred px-6 pb-2 text-sm font-bold">{errors.text}</div>}
                <ReactQuill theme="snow" modules={module} value={htmlText} onChange={handleChange} style={editorStyle} className="m-6 mb-12 mt-0 no-scrollbar" />
                <div className="p-6">
                    {image && (
                        <div className="mb-8">
                            <h1 className="my-4 font-semibold text-gray-600">Fotot e ngarkuara</h1>
                            <div className="flex items-center gap-8">
                                <div className="flex flex-col gap-1">
                                    <div className="font-bold">{image.name}</div>
                                    <div className="text-gray-400 text-sm">{imageUploadedTime}</div>
                                </div>
                                <div className="w-32 h-16 bg-blue-400 rounded-xl">
                                    <img src={`${imagePreview}`} alt="img" className="w-full h-full object-cover rounded-xl" />
                                </div>
                            </div>
                        </div>
                    )}
                    {errors.image && <div className="text-bloodred text-sm pb-2 font-bold">{errors.image}</div>}
                    <input
                        id="files"
                        name="files"
                        type="file"
                        ref={fileInputRef}
                        accept="image/png, image/jpg, image/jpeg, image/svg"
                        onChange={handlePictureChange}
                        className="hidden"
                    />
                    {image ? (
                        <div className="h-[280px] flex items-center w-full p-2 bg-[#FBFEFC] rounded-xl border-2 border-dashed border-green-300 flex justify-center">
                            <div className="my-4">
                                <div className="rounded-full w-14 h-14 bg-[#E7F6EC] flex justify-center items-center mx-auto cursor-pointer">
                                    <div className="rounded-full w-6 h-6 flex justify-center items-center bg-green-600">
                                        <MdDone fontSize={16} color="white" />
                                    </div>
                                </div>
                                <div className="text-black my-4 text-center">
                                    <span className="font-semibold">Fotoja u ngarkua</span>
                                </div>
                                <div className="p-1 px-4 w-max rounded-lg text-gray-400 mx-auto cursor-pointer flex gap-2 items-center" onClick={handleClearFileInput}>
                                    <span><FiTrash2 fontSize={16} /></span>
                                    <span className="leading-3 font-semibold">Pastro ngarkimin</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <label htmlFor="files">
                            <div className="h-[280px] flex items-center w-full p-2 bg-white rounded-xl border-2 border-dashed border-gray-200 flex justify-center cursor-pointer">
                                <div className="my-4">
                                    <div className="rounded-full w-14 h-14 bg-gray-100 flex justify-center items-center mx-auto  cursor-pointer">
                                        <RiUploadCloud2Line fontSize={28} color="#555" />
                                    </div>
                                    <div className="text-appgray my-4 text-center">
                                        <span className="text-bloodred font-semibold cursor-pointer">Kliko per te ngarkuar</span> ose terheq dhe lesho
                                    </div>
                                    <div className="p-2 w-[350px] relative my-4 text-gray-400">
                                        <hr className="border border-[whitesmoke]" />
                                        <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-white px-3">
                                            OSE
                                        </div>
                                    </div>
                                    <div className="p-2 px-4 bg-bloodred w-max rounded-lg text-white mx-auto cursor-pointer">Shfleto Dosjet</div>
                                </div>
                            </div>
                        </label>
                    )}
                </div>
            </div>
        </div>
    )
}