import { LuTrash2 } from 'react-icons/lu'
import { useDispatch } from 'react-redux'
import { modalActions } from '../../store/modalSlice'
import { tBodyItemStyle } from '../users/UserListItem'
import { FiEdit } from 'react-icons/fi'
import { editItemModalActions } from '../../store/editItemModalSlice'

export default function LocationsListItem({ location }) {

    return (
        <>
            <tr className='table_row'>
                <td style={tBodyItemStyle} className="text-black font-medium w-[45%]">
                    <div className='flex items-center gap-3'>
                        <div>{location.location_name}</div>
                        {/* {item.image_path && (
                                <div>
                                    <img src={`${process.env.REACT_APP_BACKEND_HOST}/${item.image_path}`} alt='img' width={18} height={18} />
                                </div>
                            )} */}
                    </div>
                </td>
            </tr>
        </>
    )
}