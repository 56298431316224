import { useSelector } from "react-redux"
import ListingListItem from "./ListingListItem"

export default function ListingList({ listings }) {

    const selectedListings = useSelector(state => state.selectedListings.listings)

    return (
        <>
            {listings.map(listing => (
                <ListingListItem listing={listing} isSelected={selectedListings.some(selectedListing => selectedListing.listing_id === listing.listing_id)} />
            ))}
        </>
    )
}