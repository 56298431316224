import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const token = localStorage.getItem('prona_userToken') ? localStorage.getItem('prona_userToken') : null

export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin/blogs`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('prona_userToken');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getBlogs: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/getBlogs?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Blogs']
        }),
        getBlogById: build.query({
            query(id) {
                return {
                    url: `/getBlogById/${id}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Blog']
        }),
        createBlog: build.mutation({
            query: (data) => ({
                url: '/create',
                method: 'POST',
                body: data,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }),
            invalidatesTags: ['Blogs']
        }),
        deleteBlog: build.mutation({
            query(id) {
                return {
                    url: `/delete/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Blogs']
        }),
        updateBlog: build.mutation({
            query: ({ data, id }) => ({
                url: `/update/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Blogs', 'Blog']
        }),
    })
});

export const { useGetBlogsQuery, useGetBlogByIdQuery, useCreateBlogMutation, useDeleteBlogMutation, useUpdateBlogMutation } = blogApi;