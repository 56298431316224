import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteBlogMutation } from '../store/services/blogServices';
import { useDeleteAccessoryMutation } from '../store/services/accessoryServices';
import { useDeleteUserMutation } from '../store/services/userServices';
import { alertActions } from '../store/alertSlice';
import { selectedListingsActions } from '../store/selectedListingsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useCreatePropertyTypeMutation, useGetPropertyTypesQuery } from '../store/services/propertyTypeServices';
import { createItemModalActions } from '../store/createItemModalSlice';
import { useCreateSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useCreateHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useCreateFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useCreateCharacteristicMutation } from '../store/services/characteristicsServices';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function AddItemModal() {

    const details = useSelector(state => state.create_item_modal.details)
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.create_item_modal.isOpen)
    const { data: propertyTypesData } = useGetPropertyTypesQuery()

    const [text, setText] = useState('')

    const [deleteBlog] = useDeleteBlogMutation()
    const [createPropertyType] = useCreatePropertyTypeMutation();
    const [createSubPropertyType] = useCreateSubPropertyTypeMutation()
    const [createHeatingType] = useCreateHeatingTypeMutation()
    const [createFurnishingType] = useCreateFurnishingTypeMutation()
    const [createCharacteristic] = useCreateCharacteristicMutation()
    const [deleteUser] = useDeleteUserMutation()
    const [propertySelected, setPropertySelected] = useState('1')

    function closeModal() {
        dispatch(createItemModalActions.closeModal())
    }

    const handleCancel = () => {
        dispatch(createItemModalActions.closeModal())
    }

    const handleCreate = async () => {
        try {
            if (details.category === 'tip te prones') {
                const response = await createPropertyType({ type: text }).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'nentip te prones') {
                const response = await createSubPropertyType({ property_id: parseInt(propertySelected), type: text }).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'sistem te ngrohjes') {
                const response = await createHeatingType({ type: text }).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'mobilim') {
                const response = await createFurnishingType({ type: text }).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'karakteristike') {
                const response = await createCharacteristic({ name: text }).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'listings') {
                // const response = await deleteListings(ids).unwrap()
                // dispatch(alertActions.openModal({ text: response.message, type: 'success' }))
                dispatch(selectedListingsActions.clearSelectedListings())
            }
            closeModal();
            setText('')
            // openModalWithTimeout({ text: 'Operation completed successfully', type: 'success' });
        } catch (error) {
            dispatch(modalActions.closeModal())
            // openModalWithTimeout({ text: error.data.message, type: 'error' })
            console.log(error)
        }
    }

    const handlePropertySelect = (e) => {
        setPropertySelected(e.target.value)
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-2 px-4 w-[500px]">
                    <div className='flex flex-col gap-6'>
                        <h1 className="text-lg font-bold">Shto {details.category}</h1>
                        <div className='flex items-center gap-4'>
                            <div className='w-full'>
                                <input
                                    type='text'
                                    autoFocus
                                    placeholder='Shkruaj ketu'
                                    className='border border-gray-300 p-3 w-full rounded-lg text-sm'
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") handleCreate();
                                    }}
                                />
                            </div>
                            {details.category === 'nentip te prones' && (
                                <div className='w-1/3'>
                                    <div className='w-full relative'>
                                        <select className='p-3 border bg-gray-50 w-full text-sm rounded-lg appearance-none' value={propertySelected} onChange={handlePropertySelect}>
                                            {propertyTypesData?.map(type => (
                                                <option value={type.id}>{type.type}</option>
                                            ))}
                                        </select>
                                        <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                            <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between">
                            <div className="p-1 px-6 rounded-full cursor-pointer border border-gray-200 hover:bg-gray-100 text-sm"
                                onClick={handleCancel}>
                                Cancel
                            </div>
                            <div className="p-1 px-6 bg-primary text-white rounded-full cursor-pointer hover:bg-primary/80"
                                onClick={handleCreate}
                            >
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
}