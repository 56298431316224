import ArchivedListingsItem from "./ArchivedListingsItem"

export default function ArchivedListingsList({ listings }) {
    return (
        <>
            {listings.slice(0, 10).map(listing => (
                <ArchivedListingsItem listing={listing} />
            ))}
        </>
    )
}