import { createSlice } from "@reduxjs/toolkit";

const initialModalState = { isOpen: false, details: { id: null, name: null, category: null } }

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialModalState,
    reducers: {
        openModal(state, action) {
            const {id, name, category, onDelete} = action.payload.details
            state.isOpen = true
            state.details.id = id
            state.details.name = name
            state.details.category = category
        },
        closeModal(state) {
            state.isOpen = false
            state.details = { id: null, name: null, category: null, onDelete: null }
        },
    }
})

export const modalActions = modalSlice.actions;
export default modalSlice