import { createSlice } from "@reduxjs/toolkit";

const initialAlertState = { isOpen: false, text: '', type: '' }

const alertSlice = createSlice({
    name: 'alert',
    initialState: initialAlertState,
    reducers: {
        openModal(state, action) {
            state.isOpen = true
            state.text = action.payload.text
            state.type = action.payload.type
        },
        closeModal(state) {
            state.isOpen = false
        },
    }
})


export const alertActions = alertSlice.actions;
export default alertSlice

let modalTimeout;

export const openModalWithTimeout = ({ text, type }, delay = 2000) => (dispatch) => {
    if (modalTimeout) {
        clearTimeout(modalTimeout);
    }

    dispatch(alertActions.openModal({ text, type }));

    modalTimeout = setTimeout(() => {
        dispatch(alertActions.closeModal());
        modalTimeout = null;
    }, delay);
};