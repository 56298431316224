import BlogListItem from "./BlogListItem"

export default function BlogList({ blogs }) {
    return (
        <>
            {blogs.map(blog => (
                <BlogListItem blog={blog} />
            ))}
        </>
    )
}