import { createSlice } from "@reduxjs/toolkit";

const initialAccessoryBrandSelectState = {
    isOpen: false,
    data: [
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
        {
            name: 'Fiat',
            img: 'uploads/brand/fiat.png'
        },
        {
            name: 'Ford',
            img: 'uploads/brand/ford.png'
        },
        {
            name: 'Peugeot',
            img: 'uploads/brand/peugeot.png'
        },
        {
            name: 'Land Rover',
            img: 'uploads/brand/land-rover.png'
        },
        {
            name: 'Mercedes-Benz',
            img: 'uploads/brand/mercedes-benz.png'
        },
        {
            name: 'KIA',
            img: 'uploads/brand/kia.png'
        },
        {
            name: 'Nissan',
            img: 'uploads/brand/nissan.png'
        },
        {
            name: 'Volkswagen',
            img: 'uploads/brand/volkswagen.png'
        },
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
        {
            name: 'Fiat',
            img: 'uploads/brand/fiat.png'
        },
        {
            name: 'Ford',
            img: 'uploads/brand/ford.png'
        },
        {
            name: 'Peugeot',
            img: 'uploads/brand/peugeot.png'
        },
        {
            name: 'Land Rover',
            img: 'uploads/brand/land-rover.png'
        },
        {
            name: 'Mercedes-Benz',
            img: 'uploads/brand/mercedes-benz.png'
        },
        {
            name: 'KIA',
            img: 'uploads/brand/kia.png'
        },
        {
            name: 'Nissan',
            img: 'uploads/brand/nissan.png'
        },
        {
            name: 'Volkswagen',
            img: 'uploads/brand/volkswagen.png'
        },
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
        {
            name: 'Fiat',
            img: 'uploads/brand/fiat.png'
        },
        {
            name: 'Ford',
            img: 'uploads/brand/ford.png'
        },
        {
            name: 'Peugeot',
            img: 'uploads/brand/peugeot.png'
        },
        {
            name: 'Land Rover',
            img: 'uploads/brand/land-rover.png'
        },
        {
            name: 'Mercedes-Benz',
            img: 'uploads/brand/mercedes-benz.png'
        },
        {
            name: 'KIA',
            img: 'uploads/brand/kia.png'
        },
        {
            name: 'Nissan',
            img: 'uploads/brand/nissan.png'
        },
        {
            name: 'Volkswagen',
            img: 'uploads/brand/volkswagen.png'
        },
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
        {
            name: 'Fiat',
            img: 'uploads/brand/fiat.png'
        },
        {
            name: 'Ford',
            img: 'uploads/brand/ford.png'
        },
        {
            name: 'Peugeot',
            img: 'uploads/brand/peugeot.png'
        },
        {
            name: 'Land Rover',
            img: 'uploads/brand/land-rover.png'
        },
        {
            name: 'Mercedes-Benz',
            img: 'uploads/brand/mercedes-benz.png'
        },
        {
            name: 'KIA',
            img: 'uploads/brand/kia.png'
        },
        {
            name: 'Nissan',
            img: 'uploads/brand/nissan.png'
        },
        {
            name: 'Volkswagen',
            img: 'uploads/brand/volkswagen.png'
        },
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
        {
            name: 'Fiat',
            img: 'uploads/brand/fiat.png'
        },
        {
            name: 'Ford',
            img: 'uploads/brand/ford.png'
        },
        {
            name: 'Peugeot',
            img: 'uploads/brand/peugeot.png'
        },
        {
            name: 'Land Rover',
            img: 'uploads/brand/land-rover.png'
        },
        {
            name: 'Mercedes-Benz',
            img: 'uploads/brand/mercedes-benz.png'
        },
        {
            name: 'KIA',
            img: 'uploads/brand/kia.png'
        },
        {
            name: 'Nissan',
            img: 'uploads/brand/nissan.png'
        },
        {
            name: 'Volkswagen',
            img: 'uploads/brand/volkswagen.png'
        },
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
        {
            name: 'Fiat',
            img: 'uploads/brand/fiat.png'
        },
        {
            name: 'Ford',
            img: 'uploads/brand/ford.png'
        },
        {
            name: 'Peugeot',
            img: 'uploads/brand/peugeot.png'
        },
        {
            name: 'Land Rover',
            img: 'uploads/brand/land-rover.png'
        },
        {
            name: 'Mercedes-Benz',
            img: 'uploads/brand/mercedes-benz.png'
        },
        {
            name: 'KIA',
            img: 'uploads/brand/kia.png'
        },
        {
            name: 'Nissan',
            img: 'uploads/brand/nissan.png'
        },
        {
            name: 'Volkswagen',
            img: 'uploads/brand/volkswagen.png'
        },
        {
            name: 'BMW',
            img: 'uploads/brand/brand_1715696539319-933265892.png'
        },
        {
            name: 'Audi',
            img: 'uploads/brand/brand_1715696471807-202640129.png'
        },
        {
            name: 'Bentley',
            img: 'uploads/brand/brand_1715696506254-402922188.png'
        },
        {
            name: 'Ferrari',
            img: 'uploads/brand/brand_1715696564293-733068728.png'
        },
        {
            name: 'Jaguar',
            img: 'uploads/brand/brand_1715696588753-897103630.png'
        },
        {
            name: 'Acura',
            img: 'uploads/brand/brand_1715696420719-709901426.png'
        },
        {
            name: 'Alfa Romeo',
            img: 'uploads/brand/brand_1715696449085-113695844.png'
        },
        {
            name: 'Citroen',
            img: 'uploads/brand/citroen.png'
        },
    ]
}

const accessoryBrandSelectSlice = createSlice({
    name: 'accessoryBrandSelectSlice',
    initialState: initialAccessoryBrandSelectState,
    reducers: {
        openModal(state) {
            state.isOpen = true
            console.log("armir")
            // state.text = action.payload.text
            // state.type = action.payload.type
        },
        closeModal(state) {
            state.isOpen = false
        },
    }
})


export const accessoryBrandSelectActions = accessoryBrandSelectSlice.actions;
export default accessoryBrandSelectSlice