import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteBlogMutation } from '../store/services/blogServices';
import { useDeleteUserMutation } from '../store/services/userServices';
import { selectedListingsActions } from '../store/selectedListingsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useGetPropertyTypesQuery, useUpdatePropertyTypeMutation } from '../store/services/propertyTypeServices';
import { useUpdateSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useUpdateHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useUpdateFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useUpdateCharacteristicMutation } from '../store/services/characteristicsServices';
import { editItemModalActions } from '../store/editItemModalSlice';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function EditItemModal() {

    const details = useSelector(state => state.edit_item_modal.details)
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.edit_item_modal.isOpen)


    const [text, setText] = useState('')


    const [deleteBlog] = useDeleteBlogMutation()
    const [updatePropertyType] = useUpdatePropertyTypeMutation();
    const [updateHeatingType] = useUpdateHeatingTypeMutation()
    const [updateFurnishingType] = useUpdateFurnishingTypeMutation()
    const [updateCharacteristic] = useUpdateCharacteristicMutation()
    const [updateSubPropertyType] = useUpdateSubPropertyTypeMutation()
    const [deleteUser] = useDeleteUserMutation()
    const { data: propertyTypesData } = useGetPropertyTypesQuery()
    const [propertySelected, setPropertySelected] = useState(0)

    useEffect(() => {
        if (details) {
            setText(details.name)
            setPropertySelected(details.property_id)
        }
    }, [details])

    function closeModal() {
        dispatch(editItemModalActions.closeModal())
    }

    const handleCancel = () => {
        dispatch(editItemModalActions.closeModal())
    }

    const handleEdit = async () => {
        try {
            if (details.category === 'tip te prones') {
                const response = await updatePropertyType({ id: details.id, data: { type: text } }).unwrap()
                dispatch(openModalWithTimeout({ text: "Property type changed successfully", type: 'success' }))
            }
            else if (details.category === 'nentip te prones') {
                const dataToBeInserted = {}
                if (details.name !== text) {
                    dataToBeInserted.type = text
                }
                if (details.property_id !== propertySelected) {
                    dataToBeInserted.property_id = parseInt(propertySelected)
                }
                const response = await updateSubPropertyType({ id: details.id, data: dataToBeInserted }).unwrap()
                dispatch(openModalWithTimeout({ text: "Sub property type changed successfully", type: 'success' }))
            }
            else if (details.category === 'sistem te ngrohjes') {
                const response = await updateHeatingType({ id: details.id, data: { type: text } }).unwrap()
                dispatch(openModalWithTimeout({ text: "Heating type changed successfully", type: 'success' }))
            }
            else if (details.category === 'mobilim') {
                const response = await updateFurnishingType({ id: details.id, data: { type: text } }).unwrap()
                dispatch(openModalWithTimeout({ text: "Furnishing type changed successfully", type: 'success' }))
            }
            else if (details.category === 'karakteristike') {
                const response = await updateCharacteristic({ id: details.id, data: { name: text } }).unwrap()
                dispatch(openModalWithTimeout({ text: "Characteristic changed successfully", type: 'success' }))
            }
            else if (details.category === 'listings') {
                // const response = await deleteListings(ids).unwrap()
                // dispatch(alertActions.openModal({ text: response.message, type: 'success' }))
                dispatch(selectedListingsActions.clearSelectedListings())
            }
            closeModal();
            setText('')
            // openModalWithTimeout({ text: 'Operation completed successfully', type: 'success' });
        } catch (error) {
            dispatch(modalActions.closeModal())
            // openModalWithTimeout({ text: error.data.message, type: 'error' })
            console.log(error)
        }
    }

    const handlePropertySelect = (e) => {
        setPropertySelected(e.target.value)
    }

    useEffect(() => {
        console.log({ propertySelected })
    }, [propertySelected])

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-2 px-4 w-[500px]">
                    <h1 className="text-lg font-bold leading-8">Ndrysho {details.category}</h1>
                    <div className='flex items-center gap-4'>
                        <div className='w-2/3'>
                            <input
                                type='text'
                                autoFocus
                                placeholder='Shkruaj ketu'
                                className='border border-gray-300 p-3 mt-4 mb-6 w-full rounded-lg text-sm'
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") handleEdit();
                                }}
                            />
                        </div>
                        {details.category === 'nentip te prones' && (
                            <div className='w-1/3'>
                                <select className='p-3 mt-4 mb-6 border bg-gray-50 w-full text-sm rounded-lg' value={propertySelected} onChange={handlePropertySelect}>
                                    {propertyTypesData?.map(type => (
                                        <option value={type.id}>{type.type}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-between">
                        <div className="p-1 px-6 rounded-full cursor-pointer border border-gray-200 hover:bg-gray-100 text-sm"
                            onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="p-1 px-6 bg-primary text-white rounded-full cursor-pointer hover:bg-primary/80"
                            onClick={handleEdit}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
}