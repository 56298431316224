import UserListItem from "./UserListItem"

export default function UserList({ users }) {
    return (
        <>
            {users.slice(0, 10).map(user => (
                <UserListItem user={user} />
            ))}
        </>
    )
}