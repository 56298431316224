import { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import DashboardFilters from "../DashboardFilters";
import DashboardTable from "../DashboardTable";
import ArchivedPaymentsList from "./ArchivedPaymentsList";
import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi";

const archived_payments = [
    {
        listing_id: '24221',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Basic',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
    {
        listing_id: '76355',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Premium',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
    {
        listing_id: '2534',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Unlimited',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
    {
        listing_id: '23456',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Premium',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
    {
        listing_id: '43879',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Basic',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
    {
        listing_id: '81213',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Unlimited',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
    {
        listing_id: '70968',
        full_name: 'Armir Qurdina',
        email: 'armiqurdina@gmail.com',
        payment_type: 'Basic',
        plan_started: '01.08.2024',
        plan_ended: '01.09.2024'
    },
]

export default function ArchivedPayments() {

    const [filteredArchivedPayments, setFilteredArchivedPayments] = useState(archived_payments || null)
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)

    const handleFilterSelect = (e) => {
        if (filterSelected === e.target.innerText) {
            setFilterSelected("")
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    useEffect(() => {
        if (!filterSelected.length) {
            setFilteredArchivedPayments(archived_payments)
            return;
        }
        setFilteredArchivedPayments(archived_payments.filter(payment => payment.payment_type === filterSelected))
    }, [filterSelected])

    const details = {
        title: 'Archived Payments',
        count: archived_payments.length,
    }

    const buttons = [
        {
            label: 'Back',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            linkTo: '/payments',
            onClick: null,
            shadow: true
        }
    ]

    const tableData = {
        headLabels: ['Listing ID', 'Name & Surname', 'Email', 'Payment Type', 'Plan Started', 'Plan Ended'],
        list: <ArchivedPaymentsList payments={filteredArchivedPayments} />,
        data: filteredArchivedPayments
    }

    const filters = {
        categories: ['Basic', 'Premium', 'Unlimited'],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    return (
        <>
            <DashboardHeader details={details} buttons={buttons} />
            <hr />
            <DashboardFilters filters={filters} />
            <DashboardTable tableData={tableData} />
            <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div>
        </>
    )
}