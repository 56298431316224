import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions } from "../store/alertSlice";

export default function DashboardHeader({ details, buttons }) {
    return (

        <div className="flex justify-between items-center p-6 py-4 leading-none">
            <div className="flex gap-2 items-center">
                <h1 className="text-lg font-bold">{details.title}</h1>
                {details.count > -1 && <div className="rounded-md border border-gray-300 text-xs p-1 px-2 text-gray-600 font-medium">{details.count} {details.title}</div>}
            </div>
            <div className="flex gap-3">
                {buttons.map((button, index) => (
                    <div key={index}>
                        {button.linkTo && (
                            <div className={`${button.color.includes('primary') ? 'bg-primary' : 'bg-none'} h-[40px] p-[1px] rounded-xl`}>
                                <Link to={button.linkTo} className={`relative text-sm font-bold p-2 px-4 ${button.color} ${button.border} ${button.color.includes('primary') ? 'h-[98%]' : 'h-full'} rounded-xl flex items-center gap-2 ${button.shadow} hover:brightness-95`}>
                                    {button.icon && <div className="relative leading-3 text-lg">{button.icon}</div>}
                                    <div className="relative">{button.label}</div>
                                    {/* {button.color.includes('primary') && <div className="bg-yellow-300/20 rounded-xl absolute top-0 left-0 w-full h-[95%]"></div>} */}
                                </Link>
                            </div>
                        )}
                        {button.onClick && (
                            <div className={`${button.color.includes('primary') ? 'bg-primary' : 'bg-none'} h-[40px] p-[1px] rounded-xl cursor-pointer`} onClick={button.onClick}>
                                <div className={`relative text-sm font-bold p-2 px-4 ${button.color} ${button.border} ${button.color.includes('primary') ? 'h-[98%]' : 'h-full'} rounded-xl flex items-center gap-2 ${button.shadow} hover:brightness-95`}>
                                    {button.icon && <div className="relative leading-3 text-lg">{button.icon}</div>}
                                    <div className="relative">{button.label}</div>
                                    {/* {button.color.includes('primary') && <div className="bg-yellow-300/20 rounded-xl absolute top-0 left-0 w-full h-[95%]"></div>} */}
                                </div>
                            </div>
                        )}
                    </div>
                )
                )}
            </div>
        </div>
    );
}