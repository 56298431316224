import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const listingApi = createApi({
    reducerPath: 'listingApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`, prepareHeaders: (headers) => {
            const token = localStorage.getItem('prona_userToken');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getListings: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/admin/listings?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Listings']
        }),
        getArchivedListings: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/admin/archive/listings?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Archived Listings']
        }),
        getAdvancedSearchListings: build.query({
            query(params) {
                return {
                    url: `/listings/advancedSearch?${params}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
        }),
        getListingById: build.query({
            query(id) {
                return {
                    url: `/admin/listings/${id}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Listing']
        }),
        updateListing: build.mutation({
            query({ id, data }) {
                return {
                    url: `/admin/listings/edit/${id}`,
                    method: 'PUT',
                    body: data
                }
            },
            invalidatesTags: ['Listings', 'Listing']
        }),
        softDeleteById: build.mutation({
            query(id) {
                return {
                    url: `/admin/listings/softDelete/${id}`,
                    method: 'PATCH',
                }
            },
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled; 
                } catch (error) {
                    return error
                }
            },
            invalidatesTags: ['Listings', 'Archived Listings']
        }),
        deleteManyListings: build.mutation({
            query(ids) {
                return {
                    url: '/admin/listings/softDeleteMultiple',
                    method: 'PATCH',
                    body: { listingsToDelete: ids }
                }
            },
            invalidatesTags: ['Listings']
        }),
        changeListingPauseStatus: build.mutation({
            query({ id, data }) {
                return {
                    url: `/listings/changePause/${id}`,
                    method: 'PATCH',
                    body: data
                }
            },
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    return error
                }
            },
            invalidatesTags: ['Listings']
        }),
        changeListingStatus: build.mutation({
            query({ id, data }) {
                return {
                    url: `/admin/listings/changeStatus/${id}`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: ['Listings']
        })
    }),
});

export const {
    useGetListingsQuery,
    useGetArchivedListingsQuery,
    useLazyGetListingsQuery,
    useGetListingByIdQuery,
    useGetAdvancedSearchListingsQuery,
    useSoftDeleteByIdMutation,
    useChangeListingPauseStatusMutation,
    useChangeListingStatusMutation,
    useUpdateListingMutation,
    useDeleteManyListingsMutation } = listingApi;