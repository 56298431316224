import { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { accessoryBrandSelectActions } from '../store/accessoryBrandSelectSlice';

const customStyles = {
    content: {
        width: '80%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        background: 'none',
        border: 'none',
        height: '720px',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function BrandSelectModal() {

    const brands = useSelector(state => state.accessoryBrandSelect)
    const [filteredBrands, setFilteredBrands] = useState(brands.data || [])
    const dispatch = useDispatch()

    if (brands?.isOpen) {
        document.body.style.overflow = 'hidden'
    }

    const handleClose = () => {
        console.log('armir')
        dispatch(accessoryBrandSelectActions.closeModal())
        document.body.style.overflow = 'auto'
    }

    // const handleAfterClose = () => {
    //     document.body.style.overflow = 'auto'; // Restore scrolling when modal is closed
    // };

    // return (
    //     <Modal
    //         isOpen={brands?.isOpen}
    //         onRequestClose={closeModal}
    //         style={customStyles}
    //         contentLabel="Example Modal"
    //     // onAfterClose={handleAfterClose}
    //     >
    //         <div className="bg-white w-[80%] mx-auto max-h-[800px] [&>*:nth-child(even)]:bg-[#F9FAFB] rounded-md overflow-scroll no-scrollbar">
    //             {brands?.data.map(brand => (
    //                 <div className='w-full flex items-center gap-4 cursor-pointer hover:!bg-primary/10 hover:!border-primary/50'>
    //                     <div className='w-16 h-16 aspect-square flex justify-center items-center' title={brand.name}>
    //                         <img src={`${process.env.REACT_APP_BACKEND_HOST}/${brand.img}`} alt='img' className='w-full' />
    //                     </div>
    //                     <div className='text-md'>{brand.name}</div>
    //                 </div>
    //             ))}
    //         </div>
    //     </Modal>
    // )

    const handleSearchChange = (e) => {
        if (e.target.value.length < 1) {
            setFilteredBrands(brands?.data || [])
        }
        setFilteredBrands(brands?.data.filter(brand => brand.name.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }

    const handleBrandSelect = (e) => {
        e.stopPropagation()
        console.log(e.currentTarget.getAttribute('name'))
    }

    return (
        <div>

            <div className='absolute bg-black opacity-80 top-0 left-0 z-10 w-screen h-screen'>
            </div>
            <div className='absolute top-0 left-0 w-full h-screen flex justify-center items-center z-50' onClick={handleClose}>
                <div className="w-3/4 h-[90%] bg-white max-h-[90%] overflow-scroll no-scrollbar my-8 mx-auto rounded-md" onClick={(e) => e.stopPropagation()}>
                    <div className='flex justify-center'>
                        <input type='text' placeholder='Search brand' className='w-1/2 p-2 px-3 m-4 border border-gray-400 rounded-xl' onChange={handleSearchChange} />
                    </div>
                    <div className='flex flex-wrap [&>*]:border'>
                        {filteredBrands.map(brand => (
                            <div name={brand.name} className='basis-[10%] aspect-square cursor-pointer hover:!bg-primary/10 hover:!border-primary/50' onClick={(e) => handleBrandSelect(e)}>
                                <div className='aspect-square flex justify-center items-center' title={brand.name}>
                                    <img src={`${process.env.REACT_APP_BACKEND_HOST}/${brand.img}`} alt='img' className='w-3/4' />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}