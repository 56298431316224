import { useDispatch } from 'react-redux';
import { alertActions } from '../store/alertSlice';

const useAlertDispatchActions = () => {
    const dispatch = useDispatch();

    const openModalWithTimeout = ({ text, type }) => {
        dispatch(alertActions.openModal({ text, type }));
    };

    return { openModalWithTimeout };
};

export default useAlertDispatchActions;
