import SubCategoryListItem from "./SubCategoryListItem";

export default function SubCategoryList({ items, category }) {
    return (
        <>
            {items.map(item => (
                <SubCategoryListItem key={item.id} item={item} category={category} />
            ))}
        </>
    )
}