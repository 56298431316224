import { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import DashboardFilters from "../DashboardFilters";
import DashboardTable from "../DashboardTable";
import ArchivedListingsList from "./ArchivedListingsList";
import { useGetArchivedListingsQuery } from "../../store/services/listingServices";
import { useNavigate } from "react-router";
import Pagination from "../Pagination";

const archived_listings = [
    {
        id: '',
        name: '',
        created_at: '',
        user_name: 'Auto Nesi',
        status: 'Approved',
    },
]

export default function ArchivedListings() {

    const [filteredArchivedListings, setFilteredArchivedListings] = useState(archived_listings || null)
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    // const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({})
    
    const { data } = useGetArchivedListingsQuery(params)
    const [query, setQuery] = useState(new URLSearchParams(window.location.search))
    const navigate = useNavigate()

    useEffect(() => {
        if (data) {
            console.log({ data })
            setFilteredArchivedListings(data)
            setPages({ page: data.currentPage, pageSize: data.totalPages, total: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("status")) {
            setFilterSelected(query.get("status"))
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/listings/archived?${urlParams}`)
    }, [params, navigate])

    const handleFilterSelect = (e) => {
        setParams({ ...params, status: e.target.id, page: 1 })
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['status'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleSearch = (e) => {
        // if (!e.target.value) {
        //     setFilteredListings(data)
        //     return
        // }
        // const filteredListings = { ...data, data: data.data.filter(listing => listing.name.toLowerCase().startsWith(e.target.value.toLowerCase())) }
        // setFilteredListings(filteredListings)
    }

    useEffect(() => {
        // if (!filterSelected.length) {
        //     setFilteredArchivedListings(archived_listings)
        //     return;
        // }
        // setFilteredArchivedListings(archived_listings.filter(listing => listing.status === filterSelected))
    }, [filterSelected])

    const details = {
        title: 'Archived Listings',
        count: archived_listings?.totalItems,
    }

    const buttons = [
        {
            label: 'Back',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            linkTo: '/listings',
            onClick: null,
            shadow: true
        }
    ]

    const tableData = {
        headLabels: ['Listing ID', 'Listing Image', 'Listing Name', 'Created Date', 'User', 'Status'],
        list: <ArchivedListingsList listings={filteredArchivedListings?.data} />,
        data: filteredArchivedListings?.data
    }

    const filters = {
        categories: ['Approved', 'In Progress', 'Draft', 'Declined'],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    return (
        <>
            <DashboardHeader details={details} buttons={buttons} />
            <hr />
            <DashboardFilters filters={filters} />
            <DashboardTable tableData={tableData} />
            <Pagination currentPage={currentPage} totalPages={Math.ceil(data?.totalCount / 10)} onPageChange={handlePageChange} />
            {/* <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div> */}
        </>
    )
}