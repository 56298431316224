import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const locationsTypeApi = createApi({
    reducerPath: 'locationsTypeApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin/locations`, prepareHeaders: (headers) => {
            const token = localStorage.getItem('prona_userToken');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getLocations: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/getAll?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Locations']
        }),
    }),
});

export const {
    useGetLocationsQuery
} = locationsTypeApi;