import { LuTrash2 } from "react-icons/lu";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import { useNavigate } from "react-router";

export default function PaymentsListItem({ payment }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <>
            <tr className="table_row">
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{payment.listing_id}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">{payment.full_name}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{payment.email}</td>
                {payment.payment_type === 'Basic' && <td style={tBodyItemStyle} className="w-[20%]">{payment.payment_type} Plan <span className="font-semibold">6.99€</span></td>}
                {payment.payment_type === 'Premium' && <td style={tBodyItemStyle} className="w-[20%]">{payment.payment_type} Plan <span className="font-semibold">16.99€</span></td>}
                {payment.payment_type === 'Unlimited' && <td style={tBodyItemStyle} className="w-[20%]">{payment.payment_type} Plan <span className="font-semibold">39.99€</span></td>}
                <td style={tBodyItemStyle} className="w-[20%]">{payment.plan}</td>
                <td style={tBodyItemStyle} className="w-[20%]">
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions" onClick={() => dispatch(modalActions.openModal({ details: { id: payment.id, name: payment.full_name, category: 'payment' } }))}>
                            <LuTrash2 style={{ fontSize: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                </div>
            </td>
        </tr >
        </>
    )
}