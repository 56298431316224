import PaymentsListItem from "./PaymentsListItem"

export default function PaymentsList({ payments }) {
    return (
        <>
            {payments.slice(0, 10).map(payment => (
                <PaymentsListItem payment={payment} />
            ))}
        </>
    )
}