import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const bodyTypeApi = createApi({
    reducerPath: 'bodyTypeApi',
    baseQuery: (fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api` })),
    endpoints: (build) => ({
        getBodyTypes: build.query({
            query() {
                return {
                    url: '/body_type/getAllbodyTypes',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Body Types']
        }),
    }),
});

export const { useLazyGetBodyTypesQuery, useGetBodyTypesQuery } = bodyTypeApi;