import { tBodyItemStyle } from "../users/UserListItem"

export default function ArchivedPaymentsItem({ payment }) {

    return (
        <>
            <tr className="table_row">
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{payment.listing_id}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">{payment.full_name}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{payment.email}</td>
                {payment.payment_type === 'Basic' && <td style={tBodyItemStyle} className="w-[20%]">{payment.payment_type} Plan <span className="font-semibold">6.99€</span></td>}
                {payment.payment_type === 'Premium' && <td style={tBodyItemStyle} className="w-[20%]">{payment.payment_type} Plan <span className="font-semibold">16.99€</span></td>}
                {payment.payment_type === 'Unlimited' && <td style={tBodyItemStyle} className="w-[20%]">{payment.payment_type} Plan <span className="font-semibold">39.99€</span></td>}
                <td style={tBodyItemStyle} className="w-[20%]">Plan Started - <span className="font-semibold">{payment.plan_started}</span></td>
                <td style={tBodyItemStyle} className="w-[20%]">Plan Ended - <span className="font-semibold">{payment.plan_ended}</span></td>
            </tr>
        </>
    )
}