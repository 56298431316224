import { configureStore } from "@reduxjs/toolkit";
import menuSlice from "./menuSlice";
import modalSlice from "./modalSlice";
import { blogApi } from "./services/blogServices";
import { userApi } from "./services/userServices";
import authSlice from "./authSlice";
import { accessoryApi } from "./services/accessoryServices";
import { listingApi } from "./services/listingServices";
import alertSlice from "./alertSlice";
import userSlice from "./userSlice";
import { bodyTypeApi } from "./services/bodyTypeServices";
import { exteriorColorApi } from "./services/exteriorColorServices";
import { fuelApi } from "./services/fuelServices";
import selectedListingsSlice from "./selectedListingsSlice";
import { brandApi } from "./services/brandServices";
import accessoryBrandSelectSlice from "./accessoryBrandSelectSlice";
import characteristicsModalSlice from "./characteristicsModalSlice";
import { propertyTypeApi } from "./services/propertyTypeServices";
import { heatingTypeApi } from "./services/heatingTypeServices";
import { characteristicsApi } from "./services/characteristicsServices";
import { subPropertyTypeApi } from "./services/subPropertyServices";
import { furnishingTypeApi } from "./services/furnishingTypeServices";
import createItemModalSlice from "./createItemModalSlice";
import editItemModalSlice from "./editItemModalSlice";
import { locationsTypeApi } from "./services/locationsServices";

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        menu: menuSlice.reducer,
        modal: modalSlice.reducer,
        create_item_modal: createItemModalSlice.reducer,
        edit_item_modal: editItemModalSlice.reducer,
        auth: authSlice.reducer,
        alert: alertSlice.reducer,
        characteristics_modal: characteristicsModalSlice.reducer,
        selectedListings: selectedListingsSlice.reducer,
        accessoryBrandSelect: accessoryBrandSelectSlice.reducer,
        [listingApi.reducerPath]: listingApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [accessoryApi.reducerPath]: accessoryApi.reducer,
        [bodyTypeApi.reducerPath]: bodyTypeApi.reducer,
        [exteriorColorApi.reducerPath]: exteriorColorApi.reducer,
        [fuelApi.reducerPath]: fuelApi.reducer,
        [brandApi.reducerPath]: brandApi.reducer,
        [propertyTypeApi.reducerPath]: propertyTypeApi.reducer,
        [subPropertyTypeApi.reducerPath]: subPropertyTypeApi.reducer,
        [heatingTypeApi.reducerPath]: heatingTypeApi.reducer,
        [characteristicsApi.reducerPath]: characteristicsApi.reducer,
        [furnishingTypeApi.reducerPath]: furnishingTypeApi.reducer,
        [locationsTypeApi.reducerPath]: locationsTypeApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            listingApi.middleware,
            blogApi.middleware,
            userApi.middleware,
            accessoryApi.middleware,
            bodyTypeApi.middleware,
            exteriorColorApi.middleware,
            fuelApi.middleware,
            brandApi.middleware,
            propertyTypeApi.middleware,
            subPropertyTypeApi.middleware,
            heatingTypeApi.middleware,
            characteristicsApi.middleware,
            furnishingTypeApi.middleware,
            locationsTypeApi.middleware,
        ),
})

export default store;