import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const fuelApi = createApi({
    reducerPath: 'fuelApi',
    baseQuery: (fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api` })),
    endpoints: (build) => ({
        getFuelTypes: build.query({
            query() {
                return {
                    url: '/fuel/getAllFuelTypes',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Fuels']
        }),
    }),
});

export const { useLazyGetFuelTypesQuery, useGetFuelTypesQuery } = fuelApi;