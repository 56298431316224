import { GoDotFill } from "react-icons/go"
import { getStatusClasses } from "./ListingListItem"
import { LuTrash2 } from "react-icons/lu"
import { tBodyItemStyle } from "../users/UserListItem"
import { CiImageOff } from "react-icons/ci";

export default function ArchivedListingsItem({ listing }) {

    function capitalizeFirstLetter(string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function convertToLocaleDateString(date) {
        return new Date(date).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(/\//g, '.');
    }

    const mainImage = listing.images.find(image => image.is_main)

    return (
        <>
            <tr className="table_row">
                <td style={tBodyItemStyle} className="text-black font-medium w-[8%]">{listing.listing_id}</td>
                <td style={{ ...tBodyItemStyle, padding: '0px 20px', }} className="text-black font-medium w-[10%]">
                    <div className="rounded-xl w-[100px] h-[50px]">
                        {mainImage ? (
                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/${mainImage.path}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                        ) : (
                            listing.images[0]?.path ? (
                                <img src={`${process.env.REACT_APP_BACKEND_HOST}/${listing.images[0].path}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                            ) : (
                                <div className="border border-gray-300 rounded-xl object-cover h-full w-full flex items-center justify-center text-xs text-gray-400">
                                    <CiImageOff size={24} />
                                </div>
                            )
                        )}
                    </div>
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{listing.title}</td>
                <td style={tBodyItemStyle} className="w-[15%]">{convertToLocaleDateString(listing.created_at)}</td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    {listing.user_type === 'individual' && `${listing.first_name} ${listing.last_name}`}
                    {listing.user_type === 'company' && `${listing.company_name}`}
                </td>
                <td style={tBodyItemStyle} className="w-[10%]">
                    <span
                        className={`flex justify-center gap-1 items-center px-2 rounded-full p-[2px] text-xs font-semibold ${getStatusClasses(listing.status)}`}
                        style={{ width: '100px' }}
                    >
                        <GoDotFill /> <span>{capitalizeFirstLetter(listing.status)}</span>
                    </span>
                </td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer" title="Delete">
                            <LuTrash2 style={{ fontSize: '16px', color: 'black', textAlign: 'center' }} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )

}