import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { menuActions } from "../store/menuSlice";
import EditBlog from "./blogs/EditBlog";
import EditListing from "./listings/EditListing";
import EditUser from "./users/EditUser";

export default function EditItemComponent() {
    const { category } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuActions.changeActiveCategory({ category: category }))
    }, [dispatch, category])

    return (
        <>
            {category === 'users' && <EditUser />}
            {category === 'listings' && <EditListing />}
            {category === 'blogs' && <EditBlog />}
        </>
    )
}