import { FiEdit } from "react-icons/fi";
import { LuTrash2 } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import { useDeleteBlogMutation } from "../../store/services/blogServices";
import ReactModal from "../ReactModal";

const removeHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "")
}

export default function BlogListItem({ blog }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [deleteBlog, { isLoading, isSuccess, isError }] = useDeleteBlogMutation()

    const handleBlogDelete = (e) => {
        e.stopPropagation();
        dispatch(modalActions.openModal({ details: { id: blog.id, name: blog.title, category: 'blog' } }))
    }

    const handleRowClick = () => {
        navigate(`/blogs/${blog.id}/edit`)
    }

    return (
        <>
            <tr className="table_row" onClick={handleRowClick}>
                <td style={tBodyItemStyle} className="text-black font-medium w-1/4">{blog.title}</td>
                <td style={tBodyItemStyle} className="w-1/3">{removeHtmlTags(blog.text).length > 30 ? `${removeHtmlTags(blog.text).split(" ").slice(0, 8).join(" ")}...` : removeHtmlTags(blog.text)}</td>
                <td style={{ ...tBodyItemStyle, padding: '0px 20px', }} className="">
                    <div className="w-[100px] h-[50px]">
                        <img src={`${process.env.REACT_APP_BACKEND_HOST}/${blog.image_path}`} alt="blog_img" className="w-full h-full object-cover rounded-xl" />
                    </div>
                </td>
                <td style={tBodyItemStyle}>
                    <div className="flex justify-center gap-4 text-black">
                        <Link to={`/blogs/${blog.id}/edit`}>
                            <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions">
                                <FiEdit style={{ fontSize: '14px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                            </div>
                        </Link>
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions" onClick={handleBlogDelete}>
                            <LuTrash2 style={{ fontSize: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}