import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import DashboardTable from "../DashboardTable"
import PaymentsList from "./PaymentsList"
import { useEffect, useState } from "react"

const payments = [
    {
        listing_id: '13324',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Basic',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '34212',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Premium',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '2534',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Unlimited',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '32',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Premium',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '4564',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Basic',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '23411',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Unlimited',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '70968',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Basic',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '23940',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Basic',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '32864',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Premium',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '32864',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Premium',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '32864',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Premium',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
    {
        listing_id: '32864',
        full_name: 'Arber Bakija',
        email: 'arberbakija@gmail.com',
        payment_type: 'Premium',
        plan: '30 Days / 01.08.2024 - 01.09.2024',
    },
]

export default function PaymentsDashboard() {

    const [filteredPayments, setFilteredPayments] = useState(payments || null)
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)

    const handleFilterSelect = (e) => {
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    useEffect(() => {
        if (!filterSelected.length) {
            setFilteredPayments(payments)
            return;
        }
        setFilteredPayments(payments.filter(payment => payment.payment_type === filterSelected))
    }, [filterSelected])

    const buttons = [
        { label: 'Archived', color: 'bg-white', border: 'border border-[#D0D5DD]', shadow: true, onClick: null, linkTo: '/payments/archived' }
    ]

    const details = {
        title: 'Payments',
        count: payments.length
    }

    const tableData = {
        headLabels: ['Listing ID', 'Name & Surname', 'Email', 'Payment Type', 'Plan Duration'],
        list: <PaymentsList payments={filteredPayments} />,
        data: filteredPayments,
        actions: true
    }

    const filters = {
        categories: ['Basic', 'Premium', 'Unlimited'],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters
    }

    return (
        <>
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <DashboardFilters filters={filters} />
            <div className="w-full">
                <DashboardTable tableData={tableData} />
            </div>
            <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <PiArrowLeftBold style={{ fontSize: '18px' }} />
                    <div className="leading-3">Previous</div>
                </div>
                <div>Pages</div>
                <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                    <div className="leading-3">Next</div>
                    <PiArrowRightBold style={{ fontSize: '18px' }} />
                </div>
            </div>
        </>
    )
}