import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin/users`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('prona_userToken');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getUsers: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return `/getUsers?${queryParams}`;
            },
            providesTags: ['Users']
        }),
        getAllUsers: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/users/getAllActiveUsers?${queryParams}`
                }
            },
            // providesTags: ['Users']
        }),
        getUserById: build.query({
            query(id) {
                return {
                    url: `/getUserById/${id}`,
                }
            },
            providesTags: ['User']
        }),
        deleteUser: build.mutation({
            query(id) {
                return {
                    url: `/delete/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Users']
        }),
        createUser: build.mutation({
            query: (data) => ({
                url: '/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Users']
        }),
        updateUser: build.mutation({
            query: ({ id, data }) => ({
                url: `/update/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Users', 'User']
        }),
    }),
});

export const { useGetUsersQuery, useGetAllUsersQuery, useGetUserByIdQuery, useDeleteUserMutation, useCreateUserMutation, useUpdateUserMutation, useLazyGetUsersQuery } = userApi;