import { Checkbox } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectedListingsActions } from "../store/selectedListingsSlice"

export default function ListingDashboardTable({ tableData }) {

    const { headLabels, list, data } = tableData

    const selectedListings = useSelector(state => state.selectedListings)
    const dispatch = useDispatch()

    const tHeadStyle = {
        padding: '10px 20px',
        fontSize: '12px',
        fontWeight: '500',
        color: '#595959'
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            dispatch(selectedListingsActions.selectAllListings({ listings: data }))
        }
        else {
            dispatch(selectedListingsActions.clearSelectedListings())
        }
    }

    return (
        <table className="w-full text-[14px] text-[#595959]">
            <thead>
                <tr className="bg-[#F9FAFB] border-b border-[#eee]">
                    {headLabels.map(label => (
                        <td key={label.id} style={tHeadStyle}>{label}</td>
                    ))}
                    {!headLabels.includes('Plan Ended') && <td style={tHeadStyle} className="text-center">Actions</td>}
                    <td style={tHeadStyle} className="w-[5%]">
                        <div className="flex gap-3 justify-center items-center">
                            <span><Checkbox sx={{ padding: 0, margin: 0 }} size="small" onChange={handleSelectAll} checked={selectedListings.all} /></span>
                            {/* {selectedListings.length > 0 && <MdDeleteOutline size={20} cursor={"pointer"} onClick={() => { }} />} */}
                        </div>
                    </td>
                </tr>
            </thead>
            {data && (
                <tbody className="[&>*:nth-child(even)]:bg-[#F9FAFB]">
                    {list}
                </tbody>
            )}
        </table>
    )
}