import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const heatingTypeApi = createApi({
    reducerPath: 'heatingTypeApi',
    baseQuery: (fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`, prepareHeaders: (headers) => {
            const token = localStorage.getItem('prona_userToken');
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })),
    endpoints: (build) => ({
        getHeatingTypes: build.query({
            query() {
                return {
                    url: '/heating_types/getHeatingTypes',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Heating Types']
        }),
        createHeatingType: build.mutation({
            query: (data) => ({
                url: '/admin/heating_types/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Heating Types']
        }),
        deleteHeatingType: build.mutation({
            query(id) {
                return {
                    url: `/admin/heating_types/delete/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Heating Types']
        }),
        updateHeatingType: build.mutation({
            query({ id, data }) {
                return {
                    url: `/admin/heating_types/update/${id}`,
                    method: 'PUT',
                    body: data
                }
            },
            invalidatesTags: ['Heating Types']
        }),
    }),
});

export const {
    useLazyGetHeatingTypesQuery,
    useGetHeatingTypesQuery,
    useCreateHeatingTypeMutation,
    useDeleteHeatingTypeMutation,
    useUpdateHeatingTypeMutation
} = heatingTypeApi;