import { createSlice } from "@reduxjs/toolkit";

const initialEditItemModalState = { isOpen: false, details: { id: null, name: null, category: null, property_id: null } }

const editItemModalSlice = createSlice({
    name: 'edit_item_modal',
    initialState: initialEditItemModalState,
    reducers: {
        openModal(state, action) {
            const {id, name, category, property_id, onDelete} = action.payload.details
            state.isOpen = true
            state.details.id = id
            state.details.name = name
            state.details.category = category
            state.details.property_id = property_id
        },
        closeModal(state) {
            state.isOpen = false
            state.details = { id: null, name: null, category: null, onDelete: null, property_id: null }
        },
    }
})

export const editItemModalActions = editItemModalSlice.actions;
export default editItemModalSlice