import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const exteriorColorApi = createApi({
    reducerPath: 'exteriorColorApi',
    baseQuery: (fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api` })),
    endpoints: (build) => ({
        getExteriorColors: build.query({
            query() {
                return {
                    url: '/exterior_color/getAllExteriorColors',
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Exterior Colors']
        }),
    }),
});

export const { useLazyGetExteriorColorsQuery, useGetExteriorColorsQuery } = exteriorColorApi;