import { useNavigate, useParams } from 'react-router-dom';
import UserDashboard from './users/UserDashboard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { menuActions } from '../store/menuSlice';
import ListingDashboard from './listings/ListingDashboard';
import BlogDashboard from './blogs/BlogDashboard';
import PaymentsDashboard from './payments/PaymentsDashboard';
import PropertyTypesDashboard from './property_types/PropertyTypesDashboard';
import SubPropertyTypesDashboard from './sub_property_types/SubPropertyTypesDashboard';
import HeatingTypesDashboard from './heating_types/HeatingTypesDashboard';
import FurnishingTypesDashboard from './furnishing_types/FurnishingTypesDashboard';
import CharacteristicsDashboard from './characteristics/CharacteristicsDashboard';
import LocationsDashboard from './locations/LocationsDashboard';

export default function CategoryComponent() {
  const { category } = useParams(); // category will be 'users' for '/users'

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!category) {
      navigate('/users')
      dispatch(menuActions.changeActiveCategory({ category: 'users' }))
      return;
    }
    dispatch(menuActions.changeActiveCategory({ category: category }))
  }, [dispatch, category, navigate])

  return (
    <>
      {category === 'users' && <UserDashboard />}
      {category === 'listings' && <ListingDashboard />}
      {category === 'payments' && <PaymentsDashboard />}
      {category === 'blogs' && <BlogDashboard />}
      {category === 'property_types' && <PropertyTypesDashboard />}
      {category === 'sub_property_types' && <SubPropertyTypesDashboard />}
      {category === 'heating_types' && <HeatingTypesDashboard />}
      {category === 'furnishing_types' && <FurnishingTypesDashboard />}
      {category === 'characteristics' && <CharacteristicsDashboard />}
      {category === 'locations' && <LocationsDashboard /> }
    </>
  );
}