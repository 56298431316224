import { IoMdEye } from "react-icons/io";
import DashboardHeader from "../DashboardHeader";
import { MdDone, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { labelStyle } from "../users/EditUser";
import { LuTrash2 } from "react-icons/lu";
import { useEffect, useRef, useState } from "react";
import { useGetListingByIdQuery, useUpdateListingMutation } from "../../store/services/listingServices";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { characteristicsModalActions } from "../../store/characteristicsModalSlice";
import { openModalWithTimeout } from "../../store/alertSlice";
import { useGetPropertyTypesQuery } from "../../store/services/propertyTypeServices";
import { useGetHeatingTypesQuery } from "../../store/services/heatingTypeServices";
import { useGetCharacteristicsQuery } from "../../store/services/characteristicsServices";
import { AiOutlineFilePdf } from "react-icons/ai";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { extractFileNameFromPath } from "../users/EditUser";
import { RiUploadCloud2Line } from "react-icons/ri";
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GrRotateRight } from "react-icons/gr";
import { FaCheckCircle } from "react-icons/fa";
import { BsTrash3Fill } from "react-icons/bs";
import { useGetFurnishingTypesQuery } from "../../store/services/furnishingTypeServices";

const listing = {
    id: '',
    user: {
        userName: '',
    },
    name: '',
    created_at: '',
    body_type: '',
    brand: '',
    subBrand: '',
    fuel: '',
    transmission: '',
    transmission_name: '',
    km: '',
    weight: '',
    location: '',
    price: '',
    tvsh: '',
    customs: '',
    year: '',
    production_month: '',
    ps: '',
    seats: '',
    exteriorColor: '',
    exterior_color_name: '',
    cubic: '',
    description: ``,
    status: '',
    serie: '',
    registration_date: '',
    images: [
        {
            path: '',
            isCoverImage: true
        },
    ]
}

export default function EditListing() {

    // const [triggerGetBodyTypes, { data: bodyTypesData, error: isBodyTypesError, isLoading: isBodyTypesLoading }] = useLazyGetBodyTypesQuery();
    const { data: propertyTypesData } = useGetPropertyTypesQuery()
    const { data: heatingTypesData } = useGetHeatingTypesQuery()
    const { data: characteristicsData } = useGetCharacteristicsQuery()
    const { data: furnishingTypesData } = useGetFurnishingTypesQuery()
    // const { data: brandsData } = useGetBrandsQuery()

    const { id } = useParams()

    const [updateListing] = useUpdateListingMutation();

    const [editedListing, setEditedListing] = useState(listing)
    const [imagesToDelete, setImagesToDelete] = useState([])
    const navigate = useNavigate()
    const [mainImageIndex, setMainImageIndex] = useState(null)
    const [characteristicsDropdownOpen, setCharacteristicsDropdownOpen] = useState(false)
    const [heatingTypesDropdownOpen, setHeatingTypesDropdownOpen] = useState(false)
    const [furnishingTypesDropdown, setFurnishingTypesDropdown] = useState(false)
    const [newFiles, setNewFiles] = useState({})
    const inputRef = useRef()

    const { data, isLoading, isError, IsSuccess } = useGetListingByIdQuery(id)

    useEffect(() => {
        console.log({ propertyTypesData })
    }, [propertyTypesData])

    useEffect(() => {
        console.log({ editedListing })
    }, [editedListing])


    const dispatch = useDispatch()

    const tvshRef = useRef()

    function findChangedFields(data, editedListing) {
        const changedFields = {};

        // Iterate over keys of the data object
        Object.keys(data).forEach(key => {
            if (!_.isEqual(data[key], editedListing[key])) {
                changedFields[key] = { old: data[key], new: editedListing[key] };
            }
        });

        return changedFields;
    }

    // const handleTriggerBodyTypes = () => {
    //     triggerGetBodyTypes();
    // }

    useEffect(() => {
        if (data) {
            setEditedListing({ ...data, customs: (data.customs === 'true') })
            // setAccessories(JSON.parse(data.accessories))
            // if (brandsData) {
            //     setBrandLogo(brandsData.filter(brand => brand.name === data.brand))
            // }
        }
        window.scrollTo(0, 0)
    }, [data])

    const handleEditListingSubmit = async (e) => {
        e.preventDefault();
        const editedListingData = {
            ...editedListing,
            imagesToDelete: JSON.stringify(imagesToDelete),
            imageIndex: mainImageIndex
        }
        const changedFields = findChangedFields(data, editedListing)
        let dataToBeSubmitted = Object.keys(changedFields).reduce((acc, key) => {
            acc[key] = changedFields[key].new;
            return acc;
        }, {});
        if (imagesToDelete.length > 0) {
            dataToBeSubmitted['imagesToDelete'] = imagesToDelete
        }
        if (Object.keys(changedFields).includes("heating_types")) {
            const heating_ids = heatingTypesData
                .filter(heating_type => editedListing.heating_types.includes(heating_type.type))
                .map(heating_type => heating_type.id);

            dataToBeSubmitted['heating_ids'] = heating_ids.join(",")
        }
        if (Object.keys(changedFields).includes("characteristics")) {
            const character_ids = characteristicsData
                .filter(characteristic => editedListing.characteristics.includes(characteristic.name))
                .map(characteristic => characteristic.id);

            dataToBeSubmitted['characteristics'] = character_ids.join(",")
        }
        if (Object.keys(changedFields).includes("images")) {
            dataToBeSubmitted['orderedImages'] = editedListing.images 
        }
        if (Object.keys(changedFields).includes("furnishing_types")) {
            const furnishing_ids = furnishingTypesData
                .filter(furnishing_type => editedListing.furnishing_types.includes(furnishing_type.type))
                .map(furnishing_type => furnishing_type.id);

            dataToBeSubmitted['furnishing_ids'] = furnishing_ids.join(",")
        }
        if (Object.keys(changedFields).includes("property_type")) {
            const property_id = propertyTypesData
                .filter(property_type => editedListing.property_type === property_type.type)
                .map(property_type => property_type.id);

            dataToBeSubmitted['property_id'] = property_id
        }
        try {
            console.log({ dataToBeSubmitted })
            const response = await updateListing({ id: id, data: dataToBeSubmitted }).unwrap();
            navigate(-1)
            dispatch(openModalWithTimeout({ text: 'Listing updated successfully', type: 'success' }))
        }
        catch (error) {
            console.error('Failed to update item:', error);
        }
    }

    const details = {
        title: 'Edit Listing',
    }

    const buttons = [
        {
            label: 'Shiko si duket',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            icon: <IoMdEye />,
            shadow: 'shadow-sm',
            linkTo: null,
            onClick: () => {
                window.open(`https://pronascout.com/car/${id}`, '_target')
            },
        },
        {
            label: 'Cancel',
            color: 'bg-white',
            border: 'border border-[#D0D5DD]',
            shadow: 'shadow-sm',
            linkTo: null,
            onClick: () => navigate(-1),
        },
        {
            label: 'Save',
            color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]',
            border: 'border border-b-2 border-primary',
            icon: <MdDone />,
            shadow: 'shadow-md',
            linkTo: null,
            onClick: handleEditListingSubmit,
        }
    ]

    const handleEditListingChange = (e) => {
        if (e.target.id === 'customs') {
            setEditedListing({ ...editedListing, customs: e.target.checked })
            return;
        }
        setEditedListing({ ...editedListing, [e.target.name]: e.target.value })
    }

    const handleMainImage = (index) => {
        let updatedImages = [...editedListing?.images].map(image => {
            return { ...image, is_main: 0 }
        })
        updatedImages[index] = { ...updatedImages[index], is_main: 1 }
        const [selectedImage] = updatedImages.splice(index, 1);
        updatedImages.unshift(selectedImage);
        setEditedListing({ ...editedListing, images: updatedImages })
        setMainImageIndex(index)
    }

    useEffect(() => {
        console.log({ imagesToDelete })
    }, [imagesToDelete])

    const handleDeletePhoto = (index) => {
        let updatedImages = [...editedListing?.images]
        let imageToDelete = updatedImages[index]
        if (imageToDelete.is_main === 1) {
            dispatch(openModalWithTimeout({ text: 'You cannot delete the main image', type: 'error' }))
            return;
        }
        if (!updatedImages.some(image => image.is_main === 1) && index === 0) {
            dispatch(openModalWithTimeout({ text: 'You cannot delete the main image', type: 'error' }))
            return;
        }
        updatedImages.splice(index, 1);
        setEditedListing({ ...editedListing, images: updatedImages })
        setImagesToDelete([...imagesToDelete, imageToDelete.id])
        if (index < mainImageIndex) {
            const updatedIndex = mainImageIndex - 1;
            setMainImageIndex(updatedIndex)
        }
    }

    const handleHeatingTypeClick = (e) => {
        const heating_type = e.target.getAttribute('value')
        const updatedListing = { ...editedListing }
        if (updatedListing.heating_types.includes(heating_type)) {
            setEditedListing({ ...editedListing, heating_types: updatedListing.heating_types.filter(type => type !== heating_type) })
        }
        else {
            updatedListing.heating_types = [...updatedListing.heating_types, heating_type]
            setEditedListing({ ...editedListing, heating_types: updatedListing.heating_types })
        }
    }

    const handleCharacteristicsClick = (e) => {
        const name = e.target.getAttribute('value')
        const updatedListing = { ...editedListing }
        if (updatedListing.characteristics.includes(name)) {
            setEditedListing({ ...editedListing, characteristics: updatedListing.characteristics.filter(cname => cname !== name) })
        }
        else {
            updatedListing.characteristics = [...updatedListing.characteristics, name]
            setEditedListing({ ...editedListing, characteristics: updatedListing.characteristics })
        }
    }

    const handleFurnishingTypeClick = (e) => {
        const name = e.target.getAttribute('value')
        const updatedListing = { ...editedListing }
        if (updatedListing.furnishing_types.includes(name)) {
            setEditedListing({ ...editedListing, furnishing_types: updatedListing.furnishing_types.filter(fname => fname !== name) })
        }
        else {
            updatedListing.furnishing_types = [...updatedListing.furnishing_types, name]
            setEditedListing({ ...editedListing, furnishing_types: updatedListing.furnishing_types })
        }
    }

    const handleUserFilesChange = (e) => {
        setNewFiles(Array.from(e.target.files))
        inputRef.current.value = null
    }

    const handleDownloadFile = () => {
        const url = `${process.env.REACT_APP_BACKEND_HOST}/${editedListing.pdf_path}`;
        window.open(url, "_blank");
    };

    const handleDocumentDelete = () => {
        setNewFiles({})
        setEditedListing({ ...editedListing, pdf_path: null })
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === 0) {
            handleMainImage(result.source.index)
            return;
        }
        if (result.source.index === 0) {
            dispatch(openModalWithTimeout({ text: 'You cannot move the main image', type: 'error' }))
            return;
        }
        const updatedImages = Array.from(editedListing.images)
        const [reorderedItem] = updatedImages.splice(result.source.index, 1)
        updatedImages.splice(result.destination.index, 0, reorderedItem);

        setEditedListing({ ...editedListing, images: updatedImages })
    }

    const handlePhotoRotation = (index) => {
        let updatedImages = [...editedListing?.images]
        let imageToDelete = updatedImages[index]
        console.log(imageToDelete.rotate + 90)
        let updatedImageToDelete = {
            ...imageToDelete,
            rotate: imageToDelete.rotate ? imageToDelete.rotate + 90 : 90
        };
        updatedImages[index] = updatedImageToDelete
        setEditedListing({ ...editedListing, images: updatedImages })
    }

    return (
        <div>
            <DashboardHeader details={details} buttons={buttons} />
            <div className="p-6 w-full text-sm bg-[#F9FAFB] rounded-b-xl">
                <div className="flex w-full gap-6">
                    <div className="w-1/2">
                        <div>
                            <label className={labelStyle} htmlFor="title">Titulli</label>
                            <input type="text" id="title" name="title" value={editedListing.title} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2" onChange={handleEditListingChange} />
                        </div>
                        <div>
                            <label className={labelStyle} htmlFor="user_name">Name & Surname</label>
                            {editedListing.user_type === 'individual' && <input disabled type="text" id="name" name="name" value={`${editedListing.first_name} ${editedListing.last_name}`} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2" />}
                            {editedListing.user_type === 'company' && <input disabled type="text" id="company_name" name="company_name" value={`${editedListing.company_name}`} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2" />}
                        </div>
                        <div className="w-full flex gap-4">
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="property_type">Lloji i prones</label>
                                <div className="w-full relative">
                                    <select name="property_type" id="property_type" value={editedListing.property_type} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                        {/* <option value="" disabled selected>Select fuel</option> */}
                                        {propertyTypesData?.map(property_type => (
                                            <option value={property_type.type}>{property_type.type}</option>
                                        ))}
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="sub_property_type">Nenlloji i prones</label>
                                <div className="relative w-full">
                                    <input name="sub_property_type" id="sub_property_type" value={editedListing.sub_property_type} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" />
                                    {/* {body_types.map(type => (
                                            <option value={type.name}>{type.name}</option>
                                        ))} */}
                                    {/* </select> */}
                                    {/* <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="title_deed">Leter poseduese</label>
                                <div className="w-full relative">
                                    <select name="title_deed" id="title_deed" value={editedListing.title_deed} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                        {/* <option value="" disabled selected>Select fuel</option> */}
                                        <option value={1}>Po</option>
                                        <option value={0}>Jo</option>
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="construction_year">Viti i ndertimit</label>
                                <input type="text" id="construction_year" name="construction_year" value={editedListing.construction_year} onChange={handleEditListingChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium" />
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-1/2 max-1/2 relative">
                                <label className={labelStyle} htmlFor="characteristics">Karakteristikat</label>
                                <div className="relative w-full">
                                    <div className="flex items-end max-w-full bg-white gap-2 rounded-md border border-gray-300 w-full p-3 pr-8 my-3 mt-2 font-medium overflow-scroll no-scrollbar" onClick={() => setCharacteristicsDropdownOpen(!characteristicsDropdownOpen)}>
                                        {editedListing.characteristics?.length > 0 ? (
                                            editedListing.characteristics?.map((name, index) => (
                                                <>
                                                    <div className="bg-primary rounded-xl p-1 px-2 text-white text-nowrap">{name}</div>
                                                </>
                                            ))) : (
                                            <div className="bg-white rounded-xl p-1 px-2 text-white text-nowrap">_</div>)
                                        }
                                    </div>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                                {characteristicsDropdownOpen && (
                                    <div className="z-20 absolute bg-white w-full border rounded-lg -mt-3">
                                        {characteristicsData?.map(char => (
                                            <div className={`p-2 px-3 cursor-pointer hover:bg-gray-100 ${editedListing.characteristics.includes(char.name) && 'text-primary'}`} value={char.name} onClick={handleCharacteristicsClick}>{char.name}</div>
                                        ))}
                                        {/* <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div>
                                        <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div>
                                        <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div> */}
                                    </div>
                                )}
                                {/* list */}
                                {/* <div className="text-primary font-bold border-b border-b-primary leading-4 mb-4 w-max cursor-pointer">Kliko per ti shikuar te gjitha vecorite</div> */}
                            </div>
                            <div className="w-1/2 max-1/2 relative">
                                <label className={labelStyle} htmlFor="optional_features">Sistemi i ngrohjes</label>
                                <div className="relative w-full">
                                    <div className="flex bg-white gap-2 rounded-md border border-gray-300 w-full p-3 pr-8 my-3 mt-2 font-medium overflow-x-hidden" onClick={() => setHeatingTypesDropdownOpen(!heatingTypesDropdownOpen)}>
                                        {editedListing.heating_types?.length > 0 ? (
                                            editedListing.heating_types?.map(type => (
                                                <div className="bg-primary rounded-xl p-1 px-2 text-white">{type}</div>
                                            ))
                                        ) : (
                                            <div className="bg-white rounded-xl p-1 px-2 text-white">_</div>
                                        )}
                                    </div>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                                {heatingTypesDropdownOpen && (
                                    <div className="z-20 absolute bg-white w-full border rounded-lg -mt-3">
                                        {heatingTypesData?.map(heating_type => (
                                            <div className={`p-2 px-3 cursor-pointer hover:bg-gray-100 ${editedListing.heating_types.includes(heating_type.type) && 'text-primary'}`} value={heating_type.type} onClick={handleHeatingTypeClick}>{heating_type.type}</div>
                                        ))}
                                        {/* <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div>
                                        <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div>
                                        <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div> */}
                                    </div>
                                )}
                                {/* list */}
                                {/* <div className="text-primary font-bold border-b border-b-primary leading-4 mb-4 w-max cursor-pointer">Kliko per ti shikuar te gjitha vecorite</div> */}
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-1/2 max-1/2 relative z-10">
                                <label className={labelStyle} htmlFor="characteristics">Mobilimi</label>
                                <div className="relative w-full">
                                    <div className="flex items-end max-w-full bg-white gap-2 rounded-md border border-gray-300 w-full p-3 pr-8 my-3 mt-2 font-medium overflow-scroll no-scrollbar" onClick={() => setFurnishingTypesDropdown(!furnishingTypesDropdown)}>
                                        {editedListing.furnishing_types?.length > 0 ? (
                                            editedListing.furnishing_types?.map((name, index) => (
                                                <>
                                                    <div className="bg-primary rounded-xl p-1 px-2 text-white text-nowrap">{name}</div>
                                                </>
                                            ))) : (
                                            <div className="bg-white rounded-xl p-1 px-2 text-white text-nowrap">_</div>)
                                        }
                                    </div>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                                {furnishingTypesDropdown && (
                                    <div className="z-20 absolute bg-white w-full border rounded-lg -mt-3">
                                        {furnishingTypesData?.map(char => (
                                            <div className={`p-2 px-3 cursor-pointer hover:bg-gray-100 ${editedListing.furnishing_types.includes(char.type) && 'text-primary'}`} value={char.type} onClick={handleFurnishingTypeClick}>{char.type}</div>
                                        ))}
                                        {/* <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div>
                                        <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div>
                                        <div className="p-2 px-3 cursor-pointer hover:bg-gray-100" value="armir" onClick={handleHeatingTypeClick}>Armir</div> */}
                                    </div>
                                )}
                                {/* list */}
                                {/* <div className="text-primary font-bold border-b border-b-primary leading-4 mb-4 w-max cursor-pointer">Kliko per ti shikuar te gjitha vecorite</div> */}
                            </div>
                            <div className="w-1/2 max-1/2 relative">
                                <div className="w-full">
                                    <label className={labelStyle} htmlFor="location_name">Lokacioni / Shteti</label>
                                    <input type="text" id="location_name" name="location_name" value={editedListing.location_name} onChange={handleEditListingChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium" />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4 w-full">
                            <div className="w-[80%]">
                                <label className={labelStyle} htmlFor="price">Cmimi</label>
                                <input type="number" id="price" name="price" value={editedListing.price} onChange={handleEditListingChange} className="block rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium" />
                            </div>
                            <div className="w-[20%]">
                                <label className={labelStyle} htmlFor="price_unit">Njesia</label>
                                <div className="relative">
                                    <select name="price_unit" id="price_unit" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={editedListing.price_unit} onChange={handleEditListingChange}>
                                        <option value="">Select unit</option>
                                        <option value="total">total</option>
                                        <option value="m2">m2</option>
                                        <option value="ha">ha</option>
                                        <option value="Ari">Ari</option>
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="flex gap-4">
                            <div className="w-[80%]">
                                <label className={labelStyle} htmlFor="surface">Siperfaqja</label>
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        name="surface"
                                        id="surface"
                                        value={`${editedListing.surface || ''}`}
                                        className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2"
                                        onChange={handleEditListingChange}
                                    />
                                </div>
                            </div>
                            <div className="w-[20%]">
                                <label className={labelStyle} htmlFor="measurement_unit">Njesia</label>
                                <div className="relative w-full">
                                    <select name="measurement_unit" id="measurement_unit" className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" value={editedListing.measurement_unit} onChange={handleEditListingChange}>
                                        <option value="m2">m2</option>
                                        <option value="ha">ha</option>
                                        <option value="Ari">Ari</option>
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="num_rooms">Dhoma</label>
                                <div className="relative w-full">
                                    <select name="num_rooms" id="num_rooms" value={editedListing.num_rooms} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                        {/* <option value="" disabled selected>Select fuel</option> */}
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="num_bathrooms">Banjo</label>
                                <div className="relative w-full">
                                    <select name="num_bathrooms" id="num_bathrooms" value={editedListing.num_bathrooms} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                        {/* <option value="" disabled selected>Select transmission</option> */}
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="floor">Kati</label>
                                <div className="relative w-full">
                                    <input name="floor" id="floor" value={editedListing.floor} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2" />
                                    {/* <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="directions">Orientimet</label>
                                <div className="relative w-full">
                                    <select name="directions" id="directions" value={editedListing.directions} onChange={handleEditListingChange} className="appearance-none p-4 border border-gray-300 rounded-md w-full my-3 mt-2">
                                        <option value="north">North</option>
                                        <option value="south">South</option>
                                        <option value="west">West</option>
                                        <option value="east">East</option>
                                    </select>
                                    <div className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center px-3 pointer-events-none">
                                        <span className="text-gray-500"><MdOutlineKeyboardArrowDown fontSize={20} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-full">
                                <label className={labelStyle} htmlFor="pdf_path">Planimetria</label>
                                {editedListing.pdf_path ? (
                                    <div className="flex items-center gap-4">
                                        <div className="flex w-full justify-between bg-white items-center gap-4 border border-gray-300 rounded-lg p-4 my-3 mt-2 cursor-pointer" title="Download file" onClick={handleDownloadFile}>
                                            <div className="flex gap-4 items-center">
                                                <AiOutlineFilePdf color="red" size={20} />
                                                <div>{extractFileNameFromPath(editedListing.pdf_path)}</div>
                                            </div>
                                            <RiDownloadCloud2Line size={20} />
                                        </div>
                                        <div><LuTrash2 size={20} color="red" cursor="pointer" title="Delete" onClick={handleDocumentDelete} /></div>
                                    </div>
                                ) : (
                                    Object.keys(newFiles).length > 0 ? (
                                        <div className="flex items-center gap-4">
                                            <div className="flex w-full justify-between bg-white items-center gap-4 border border-gray-300 rounded-lg p-4 my-3 mt-2 cursor-pointer">
                                                <div className="flex gap-4 items-center">
                                                    <AiOutlineFilePdf color="red" size={20} />
                                                    <div>{newFiles[0].name}</div>
                                                </div>
                                            </div>
                                            <div><LuTrash2 size={20} color="red" cursor="pointer" title="Delete" onClick={handleDocumentDelete} /></div>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                id="files"
                                                name="files"
                                                type="file"
                                                accept="image/pmg, image/jpg, image/jpeg, application/pdf"
                                                className="hidden"
                                                onChange={handleUserFilesChange}
                                                ref={inputRef}
                                            />
                                            <label htmlFor="files">
                                                <div className="flex justify-between items-center bg-white rounded-md border border-gray-300 w-full p-4 my-3 mt-2 font-medium cursor-pointer">
                                                    <span>Ngarko dokument</span>
                                                    <span><RiUploadCloud2Line size={20} /></span>
                                                </div>
                                            </label>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-full flex flex-col">
                                <label className={labelStyle} htmlFor="description">Pershkrimi</label>
                                <textarea id="description" name="description" value={editedListing.description} onChange={handleEditListingChange} className="w-full min-h-80 resize-none rounded-md border border-gray-300 p-4 my-3 mt-2" style={{ overflow: 'auto' }}>

                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
                {editedListing.images && (
                    <div className="mt-8">
                        <label className={labelStyle} htmlFor="">Fotot e ngarkuara</label>
                        <div className="w-full bg-white block rounded-md border border-gray-300 w-full p-4 my-3 mt-4 font-medium">
                            {/* <div className="grid 2xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-6 gap-x-10 p-2 w-full transition-all">
                                {editedListing.images.map((img, index) => (
                                    <div className="w-full rounded-lg border overflow-hidden">
                                        <div className="relative">
                                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/${img?.path}?v=${Date.now()}`} alt="img" className={`w-full rounded-lg aspect-square object-cover transition-all`} style={{ transform: `rotate(${img.rotate || 0}deg)` }} />
                                            <div className="flex absolute top-0 left-0 w-full justify-between bg-[rgba(0,0,0,0.5)]">
                                                {img.isMainImage ? (
                                                    <div className="p-2 text-white text-xs rounded-t-lg" title="Kjo eshte foto kryesore"><span className="flex items-center gap-1"><FaStar color="var(--primary-color)" size={20} className="FaStar" /></span></div>
                                                ) : (
                                                    index === 0 && !editedListing.images.some(image => image.isMainImage) ? <div className="p-2 text-white text-xs rounded-t-lg" title="Kjo eshte foto kryesore"><span className="flex items-center gap-1"><FaStar color="var(--primary-color)" size={20} /></span></div> :
                                                        <div className="p-2 text-white text-xs underline rounded-t-lg" title="Beje foto kryesore"><span className="cursor-pointer flex items-center gap-1" onClick={() => handleMainImage(index)}><FaRegStar color="white" size={20} /></span></div>
                                                )}
                                                <div className="p-2 text-white" title="Rotate"><span className="cursor-pointer"><GrRotateRight size={20} style={{ stroke: "white", strokeWidth: "1" }} onClick={() => handlePhotoRotation(index)} /></span></div>
                                                <div className="p-2 text-red-600 rounded-br-lg rounded-tl-lg" title="Delete" onClick={() => handleDeletePhoto(index)}><span className="cursor-pointer"><BsTrash3Fill size={20} /></span></div>
                                            </div>
                                            <div className="flex absolute bottom-0 left-0 w-full justify-between bg-[rgba(0,0,0,0.5)]">
                                                <div className="p-2 text-white" title="Rotate"><span className="cursor-pointer"><span name="prev" onClick={(e) => handlePhotoSorting("prev", index)}><FaRegArrowAltCircleLeft fontSize={20} /></span></span></div>
                                                <div className="p-2 text-white" title="Rotate"><span className="cursor-pointer"><span name="next" onClick={(e) => handlePhotoSorting("next", index)}><FaRegArrowAltCircleRight fontSize={20} /></span></span></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="images" direction="vertical">
                                    {(provided) => (
                                        <div className="flex flex-col gap-2 w-full p-2 w-full transition-all" {...provided.droppableProps} ref={provided.innerRef}>
                                            {editedListing.images.map((img, index) => {
                                                return (
                                                    <Draggable key={img.path} draggableId={img.path} index={index}>
                                                        {(provided) => (
                                                            <div className="w-full border border-gray-200 bg-white p-2 rounded-lg border overflow-hidden" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                <div className="w-full flex items-center justify-between gap-8">
                                                                    <div className="flex items-center gap-4">
                                                                        <div className="relative w-32 ">
                                                                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/${img?.path}?v=${Date.now()}`} alt="img" className={`w-full rounded-lg aspect-square object-cover transition-all`} style={{ transform: `rotate(${img.rotate || 0}deg)` }} />
                                                                        </div>
                                                                        <div className="h-32 flex flex-col p-2 justify-between">
                                                                            {img.is_main ? (
                                                                                <div className="text-sm rounded-t-lg" title="Kjo eshte foto kryesore"><span className="flex items-center gap-1"><FaCheckCircle size={16} />Kjo eshte foto kryesore</span></div>
                                                                            ) : (
                                                                                index === 0 && !editedListing.images.some(image => image.is_main) ? <div className="text-sm rounded-t-lg" title="Kjo eshte foto kryesore"><span className="flex items-center gap-1"><FaCheckCircle size={16} />Kjo eshte foto kryesore</span></div> :
                                                                                    <div className="text-sm underline rounded-t-lg" title="Beje foto kryesore"><span className="cursor-pointer flex items-center gap-1" onClick={() => handleMainImage(index)}>Beje foto kryesore</span></div>
                                                                            )}
                                                                            <div className="flex items-center gap-4">
                                                                                <div title="Rotate"><span className="cursor-pointer"><GrRotateRight size={20} onClick={() => handlePhotoRotation(index)} /></span></div>
                                                                                <div className="text-red-600 rounded-br-lg rounded-tl-lg" title="Delete" onClick={() => handleDeletePhoto(index)}><span className="cursor-pointer"><BsTrash3Fill size={20} /></span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}