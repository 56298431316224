import { createSlice } from "@reduxjs/toolkit";

const initialSelectedListingsState = { listings: [], ids: [], all: false }

const selectedListingsSlice = createSlice({
    name: 'selectedListings',
    initialState: initialSelectedListingsState,
    reducers: {
        addOrRemoveListing(state, action) {
            const listing = action.payload.listing
            if (state.listings.some(selected => selected.listing_id === listing.listing_id)) {
                console.log('armir')
                const updatedSelectedListings = [...state.listings]
                const updatedIds = [...state.ids]
                const filteredSelectedListings = updatedSelectedListings.filter(list => list.listing_id !== listing.listing_id)
                const filteredIds = updatedIds.filter(id => id !== listing.listing_id)
                state.listings = filteredSelectedListings
                state.ids = filteredIds
                state.all = false
                return;
            }
            state.listings.push(listing)
            state.ids.push(listing.listing_id)
        },
        clearSelectedListings(state) {
            state.listings = []
            state.ids = []
            state.all = false
        },
        selectAllListings(state, action) {
            const listings = action.payload.listings
            // state.listings.filter(listing => listings.map(list => list.id === listing.id))
            state.listings = listings
            state.ids = listings.map(listing => listing.id)
            state.all = true
        }
    }
})


export const selectedListingsActions = selectedListingsSlice.actions;
export default selectedListingsSlice