import LocationsListItem from "./LocationsListItem";

export default function LocationsList({ locations }) {
    return (
        <>
            {locations.slice(0, 10).map(location => (
                <LocationsListItem key={location.id} location={location} />
            ))}
        </>
    )
}