import { createSlice } from "@reduxjs/toolkit";

const initialCreateItemModalState = { isOpen: false, details: { id: null, name: null, category: null } }

const createItemModalSlice = createSlice({
    name: 'create_item_modal',
    initialState: initialCreateItemModalState,
    reducers: {
        openModal(state, action) {
            const {id, name, category, onDelete} = action.payload.details
            state.isOpen = true
            state.details.id = id
            state.details.name = name
            state.details.category = category
        },
        closeModal(state) {
            state.isOpen = false
            state.details = { id: null, name: null, category: null, onDelete: null }
        },
    }
})

export const createItemModalActions = createItemModalSlice.actions;
export default createItemModalSlice