import { useEffect, useRef, useState } from "react"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import { useGetAccessoriesPerBrandQuery, useGetAccessoriesQuery } from "../../store/services/accessoryServices"
import CircularProgress from '@mui/material/CircularProgress';
import AccessoriesDashboardTable from "../AccessoriesDashboardTable"
import { useGetBrandsQuery } from "../../store/services/brandServices"
import AccessoriesFilters from "../AccessoriesFilters"
import { useNavigate } from "react-router"
import Pagination from "../Pagination"
import DashboardTable from "../DashboardTable"
import { useGetPropertyTypesQuery } from "../../store/services/propertyTypeServices"
import SubCategoryList from "../subcategories/SubCategoryList"
import { useGetSubPropertyTypesQuery } from "../../store/services/subPropertyServices"
import { BiPlus } from "react-icons/bi"
import AddItemModal from "../AddItemModal"
import { modalActions } from "../../store/modalSlice"
import { useDispatch } from "react-redux"
import { createItemModalActions } from "../../store/createItemModalSlice"
import EditItemModal from "../EditItemModal"
import ReactModal from "../ReactModal"
import LocationsList from "./LocationsList"
import { useGetLocationsQuery } from "../../store/services/locationsServices"

const countries = [
    {
        id: 1,
        name: 'Kosove'
    },
    {
        id: 2,
        name: 'Shqiperi'
    }
]

export default function LocationsDashboard() {

    const [showFilters, setShowFilters] = useState(true)
    const [filterSelected, setFilterSelected] = useState("")
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const [searchTextInput, setSearchTextInput] = useState('')
    const [actions, setActions] = useState(false)

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    // const { data, isLoading, isError, isSuccess } = useGetAccessoriesQuery();
    const { data, isFetching, isLoading, isError, isSuccess } = useGetLocationsQuery(params);
    const dispatch = useDispatch();

    const navigate = useNavigate()

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams({ ...newParams, limit: 10 });
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("q")) {
            setSearchTextInput(query.get("q"))
        }
        if (query.has("country_id")) {
            const country_id = query.get("country_id")
            const country = countries.find(country => country.id === parseInt(country_id))?.name
            console.log({ country })
            setFilterSelected(country)
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/locations?${urlParams}`)
    }, [params, navigate])


    // useEffect(() => {
    //     if (data) {
    //         setFilteredAccessories(data)
    //     }
    // }, [data])

    const debounceTimeoutRef = useRef(null);

    const handleSearch = (e) => {
        const text = e.target.value
        setSearchTextInput(text)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        if (text.length > 2) {
            debounceTimeoutRef.current = setTimeout(() => {
                setParams({ ...params, q: text, page: 1 })
            }, 500)
        }
        else {
            const updatedParams = { ...params }
            delete updatedParams['q']
            setParams(updatedParams)
        }
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
        const updatedParams = { ...params }
        const country = e.target.id
        const country_id = countries.find(c => c.name === country)?.id
        setParams({ ...updatedParams, country_id: country_id, page: 1 })
        setCurrentPage(1)
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['country_id'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(country)
    }

    const filters = {
        handleSearch: handleSearch,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        categories: ['Kosove', 'Shqiperi'],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        searchTextInput: searchTextInput,
        handleSearchTextInputChange: handleSearch,
        params: params,
    }

    const details = {
        title: 'Locations',
        count: data?.totalCount
    }

    const buttons = [
        {
            label: 'Nenipe te prones',
            color: 'bg-white',
            border: 'border border-gray-300 invisible',
            linkTo: '/sub_property_types'
        },
    ]

    const tableData = {
        headLabels: ['Name'],
        actions: actions,
        data: data,
        list: <LocationsList locations={Array.isArray(data?.data) ? data?.data : []} />
    }

    return (
        <>
            <AddItemModal />
            <EditItemModal />
            <ReactModal />
            <DashboardHeader details={details} buttons={buttons} />
            <hr />
            <DashboardFilters filters={filters} />
            <hr />
            {/* <AccessoriesFilters filters={filters} /> */}
            <div className="w-full relative">
                {isLoading ? (
                    <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                        <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                    </div>
                ) : (
                    isFetching && (
                        <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                            <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                        </div>
                    )
                )}
                <DashboardTable tableData={tableData} />
            </div>
            {!isLoading && <Pagination currentPage={currentPage} totalPages={Math.ceil(data?.totalCount / 10)} onPageChange={handlePageChange} />}
            {/* {!isLoading && <Pagination currentPage={currentPage} totalPages={Math.ceil(accessoriesPerBrandData?.total / 50)} onPageChange={handlePageChange} />} */}
            {/* <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
            <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                <PiArrowLeftBold style={{ fontSize: '18px' }} />
                <div className="leading-3">Previous</div>
            </div>
            <div>Pages</div>
            <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                <div className="leading-3">Next</div>
                <PiArrowRightBold style={{ fontSize: '18px' }} />
            </div>
        </div> */}
        </>
    )
}